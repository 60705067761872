.container {
  text-align: center;
  width: 100%;
  font-size: 16px;
  span {
    font-weight: 400;
  }
  h2 {
    margin: 10px 0px 0px 0px;
  }
}

.inputNumber {
  font-size: 20px;
  text-align: center;
  align-items: center;
  //   width: fit-content;
  display: flex;
  justify-content: center;
}

.displayTokens {
  // margin-top: 10px;
  width: 100px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  span {
    color: #7043ff;
    font-weight: 600;
    font-size: 16px;
    background-color: white;
    border: 2px solid #7043ff;
    border-radius: 8px;
    padding: 10px 30px;
    width: 100px;
  }
}

.box {
  display: flex;
  max-width: 96%;
  justify-content: flex-end;
  align-items: center;
  font-size: 15px;
}

.checkBox {
  display: flex;
  max-width: 97%;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 14px;
}

.quantity {
  display: flex;
  max-width: 96%;
  justify-content: flex-end;
  font-size: 16px;
  padding-bottom: 20px;
  align-items: center;
  font-weight: 600;
}
