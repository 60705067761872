.percentageLine{
    margin-top: 10px;
    .legend{
        display: table;
        padding: 6px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: var(--color-gray-06);
        width: 100%;
        .item{
            display: block;
            width: 100%;
            padding-right: 16px;
            margin-top: 0px;
            margin-bottom: 0px;
            .bullet{
                height: 12px;
                width: 12px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 12px;
            }
        }
    }
    .line{
        display: table;
        width: 100%;
        height: 10px;
        border-radius: 8px;
        margin-bottom: 15px;
        .cell{
            display: table-cell;
            vertical-align: middle;
            color: transparent;
        }
        .cell:first-child{
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }

        .cell:last-child{
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }
       
    }
}