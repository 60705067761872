.stepContentContainer {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.headerContainer {
  padding: 10px 0px;
  width: 100%;
  border-bottom: 1px solid #ebecf0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  gap: 10px;
  img {
    height: 24px;
    width: 24px;
  }
  p {
    font-weight: 600;
    margin: 0;
    font-size: 18px;
  }
}

.stepContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  h3 {
    color: #333;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
  }
}

.input {
  margin-bottom: 1rem;

  &:focus {
    border-color: #007bff;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    // margin-bottom: 5px;
  }
}

.firstScreen {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  h3 {
    color: #333;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    margin: 10px 0px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 0px 25px 0px;
  span {
    margin-top: 10px;
    color: #7043ff;
    font-weight: 600;
  }
}

.container {
  padding: 20px 0px;
}

.actions {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  Button {
    width: 150px;
    border-radius: 8px;
    height: 35px;
    font-weight: 600;
    margin: 0;
    border-radius: 20px;
  }
  .cancelButton {
    font-size: 12px;
    border: none;
    box-shadow: none;
    height: fit-content;
    padding: 0;
    width: fit-content;
    span {
      text-decoration: underline;
      color: #4f91ff;
      font-weight: 400;
    }
  }
}

.timer {
  display: inline;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  span {
    font-size: 14px !important;
    line-height: 1 !important;
  }
  p {
    margin: 0px;
    padding-top: 3px;
    // font-weight: ;
  }
}

.carousel {
  // margin-top: 100px;
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 15px;
  }
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  color: red;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 6px;
  img {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    Button {
      font-weight: 600;
    }
  }
}

.unknown {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0px 40px;
  align-items: center;
  p {
    font-weight: 600;
    margin: 0;
  }

  .centered {
    text-align: center;
  }
}

.loaderContainer {
  position: relative;
  img {
    position: absolute;
    top: 7px;
    left: 7px;
    background-color: transparent;
  }
  .loader {
    --border-width: 5px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    --mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border-width) - 0.5px),
      #000 calc(100% - var(--border-width) + 0.5px)
    );
    -webkit-mask: var(--mask);
    mask: var(--mask);

    background: linear-gradient(to top, #7144ff, #b649f3) 100% 0/50% 100% no-repeat,
      linear-gradient(#b649f3 50%, transparent 95%) 0 0/50% 100% no-repeat;
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.loadingScreen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  // .truncatedText {
  //   font-weight: 600;
  //   font-size: 26px;
  //   margin: 10px 0;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   max-width: 100%;
  // }
  .truncatedText {
    font-weight: 600;
    font-size: 26px;
    margin: 10px 0;
    white-space: normal; /* Allows automatic line wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    word-break: break-word; /* Breaks long words onto the next line if needed */
  }

  p {
    font-weight: 600;
    font-size: 26px;
    margin: 10px 0;
  }

  span {
    color: #5e6c84;
    height: 45px;
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p {
    font-weight: 600;
    font-size: 26px;
    margin: 10px 0px;
  }
  span {
    color: #5e6c84;
    // height: 45px;
  }
  img {
    height: 60px;
    width: 60px;
  }
}

.bullet {
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 1.5em;
}

.bodyContainer {
  display: flex;
  padding: 0px 60px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  p {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  .description {
    color: var(--Nutrals-300, #5e6c84);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.08px;
    text-align: center;
  }

  .inputCode {
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: 250px;
    .title {
      font-weight: 600;
      color: #5e6c84;
    }
  }
}

.countdown {
  text-align: center;
  display: flex;
  flex-direction: row;
  span {
    font-size: 12px !important;
    line-height: 1 !important;
    color: #5e6c84;
  }
}

.carousel {
  margin-bottom: 100px;
  p,
  span {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #5e6c84;
    margin-bottom: 15px;
  }
}
