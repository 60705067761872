.videoCard {
  height: 100%;
  width: 100%;
  border: 1px solid #dfe1e6;
}
.videoCard:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(180deg, #7144ff, #b649f3) border-box;
  border: 1px solid transparent;
}
.cover {
  height: 70%;
  width: 100%;
  padding: 0px;
  box-sizing: border-box;
}
.content {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 120px;
  height: 30%;
}
.wrapper {
  width: 98%;
  justify-content: space-between;
  height: 100%;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  letter-spacing: 0.002em;
  color: #091e42;
  align-items: center;
}
.modalTitle {
  color: var(--Nutrals-100, #051123);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.048px;
}
