.socialCard {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: auto;
}

.connectAccounts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 72px;
  width: 100%;
}

.connectIntegrations {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 90px;
  width: 100%;
}

.comingSoon {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ff9900;
}

.ant-table-tbody {
  background-color: #f9f9f9;
}

.tableContainer {
  margin-bottom: 20px;
  padding: 0 24px;
}

.tableHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.socialContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7043ff;
  margin-top: 10px;
}
.modal {
  display: flex;
  min-width: 1050px !important;
  overflow: auto !important;
}

.modal_outreach_connection {
  display: flex;
  // min-width: 520px !important;
  // width: unset !important;
  width: 1050px !important;

  overflow: auto !important;
}
.ant-modal .ant-modal-content {
  min-width: fit-content !important;
}

.ant-modal-content {
  min-width: fit-content !important;
}

.table_row_edit_btn {
  cursor: pointer;
}

.settingsIcon {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.social-page {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #dfe1e6;
    padding: 16px 24px;

    &__setting {
      color: #091e42;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;

      letter-spacing: 0.048px;
      cursor: pointer;
    }

    &__pageName {
      color: #091e42;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-transform: capitalize;
    }
  }

  &__body {
    padding: 32px 24px;
    background: #f9fafb;
    height: 100%;
    overflow: auto;

    &__section {
      margin-bottom: 48px;

      .wrapping {
        display: flex;
        gap: 25px;
      }

      &__title {
        color: white;
        color: #091e42;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.036px;
        margin-bottom: 24px;
      }
    }

    &__appIntergrate {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 24px;

      &__item {
        display: flex;
        padding: 24px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border-radius: 10px;
        border: 1px solid #dfe1e6;
        background: #fff;
        max-width: 312px;

        &__info {
          display: flex;
          align-items: flex-start;
          color: #091e42;
          font-feature-settings: 'salt' on;
          font-family: Roboto;
          font-style: normal;

          &__name {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
          }

          &__description {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 300;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            align-self: stretch;
          }
        }

        &__bottom {
          display: flex;
          height: 32px;
          padding: 4px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          align-self: stretch;
          border-radius: 16px;
          border: 1px solid var(--Nutrals-600, #dfe1e6);
          color: var(--Nutrals-500, #97a0af);
        }

        &__bottom__connected {
          cursor: pointer;
          display: flex;
          height: 32px;
          padding: 4px 0px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          align-self: stretch;
          border-radius: 16px;
          border: 1px solid var(--Nutrals-600, #7043ff);
          color: #7043ff;
        }
        &__bottom__connected:hover {
          // background-color: #f8f5ff;
          opacity: 0.8;
        }

        img {
          width: 48px;
          height: 48px;
          max-width: 48px;
          max-height: 48px;
          margin-right: 16px;
        }
      }
    }
  }
}
