.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  height: 55vh;
}

.centeredContent {
  text-align: center;
}

.container p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
}

.container section {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.05;
  margin: 0px 0px 20px 0px;
}

.form-input {
  height: 45px;
  width: 450px;
  min-width: fit-content;
}

.codeInputs {
  display: flex;
  justify-content: center;
}
.error{
  text-align: start;
}