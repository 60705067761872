.header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  p {
    font-size: 22px;
    margin: 0px;
    font-weight: 600;
  }
  img {
    padding-left: 5px;
  }
}

.autoNewlineTextParent {
  margin: 5px;
  background: linear-gradient(322.33deg, rgba(236, 97, 129, 0.16) -38.49%, rgba(49, 107, 255, 0.12) 188.04%);
}

.autoNewlineText,
.autoNewlineText:focus-visible {
  overflow: hidden;
  border: 0px solid transparent !important;

  margin: 0px 2px;

  background: linear-gradient(
    90deg,
    rgba(112, 67, 255, 0.36) 18.04%,
    rgba(129, 68, 255, 0.36) 19.72%,
    rgba(144, 68, 255, 0.36) 21.99%,
    rgba(160, 69, 255, 0.36) 23.33%,
    rgba(175, 69, 255, 0.36) 25.77%,
    rgba(190, 70, 255, 0.36) 28.37%,
    rgba(199, 70, 255, 0.36) 31.73%,
    rgba(209, 71, 255, 0.36) 33.33%,
    rgba(218, 71, 255, 0.36) 34.84%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0.072px;
  span:empty:before {
    content: attr(placeholder) !important;
    color: black !important;
  }
}

.autoNewlineText:empty:before {
  content: attr(placeholder) !important;
  color: black !important;
}
// .autoNewlineText::empty:before {
//   content: attr(placeholder);
//   color: #aaa; /* Placeholder text color */
// }

.subHeader {
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #abafb8;
  padding-bottom: 15px;
  p {
    font-family: Roboto;
    font-size: 13px;
    margin: 0px;
    font-weight: 400;
  }
}

.modalContent {
  display: flex;
  justify-content: space-between;
  min-height: 560px;
}

.leftPanel {
  width: 50%;
  // padding: 20px;
  max-height: 595px;
  overflow-y: scroll;
  padding: 20px 20px 0px 20px;
  // border-right: 1px solid #e8e8e8;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0px;
  // border-left: 1px solid #e8e8e8;
}

.previewTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.previewImage {
  // box-shadow: 0 0 10px 4px rgb(0, 0, 0, 0.05);
  border-radius: 0px;
  padding: 0px 0px;
  width: 100%;
  display: flex;
  // justify-content: center;
  height: 100%;
  background-color: #fafdff !important;
  background: radial-gradient(rgba(200, 188, 255, 0.4) 1px, transparent 0);
  background-size: 30px 30px;

  .card {
    display: flex;
    flex-direction: column;
    border-image: linear-gradient(45deg, #7043ff, #9452fe, #b860fd, #be53fe, #c447ff, #d42cff, #df19ff) 1;
    box-sizing: border-box; /* Ensure that padding is included in the width */
    border-radius: 5px; /* Add border-radius */
    border-width: 3px;
    border-style: solid;
    padding: 30px;
    margin: 20px;
    background: #fff;
    width: 100%;

    .free_text_ai {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      line-height: 30px;
      margin: 10px 3px;
      color: #000;

      font-family: Roboto;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 59px; /* 163.889% */
      letter-spacing: 0.072px;
    }
    .channels_frame {
      margin: 20px 0px;
      border-radius: 7px;
      border: 0.5px solid #7043ff;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      width: 635px;
      height: 80px;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      justify-content: flex-start;

      .unchecked {
        img {
          display: block;
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }

      .checked {
        border-radius: 6px;
        outline: 2px solid #50e2ba;
        background: var(--primary-600-main, #7043ff);
        img {
          display: block;
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }

      .channels_caption {
        margin: 20px;
        color: #051123;

        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 100% */
        letter-spacing: 0.02px;

        img {
          display: block;
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
    }

    .followups_frame {
      margin: 20px 0px;
      border-radius: 7px;
      border: 0.5px solid #7043ff;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 260px;
      height: 80px;

      .followups_select {
        border-radius: 6px;
        background: #7043ff;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .channels_caption {
        margin: 20px;
        color: #051123;

        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 100% */
        // letter-spacing: 0.02px;

        img {
          display: block;
          cursor: pointer;
          width: 40px;
          height: 40px;
        }
      }
    }

    .btn_parent {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      // justify-content: space-around;
      position: absolute;
      bottom: 120px;
      right: 50px;
      width: 550px;
    }
    .manually_frame,
    .duplicate_frame {
      display: flex;
      width: 242px;
      height: 63px;
      flex-direction: row;
      flex-shrink: 0;
      border-radius: 7px;
      border: 0.5px solid #7043ff;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      align-items: center;

      .btn_caption {
        color: #000;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: 0.03px;
      }

      .btn_sub_caption {
        color: #000;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 200;
        line-height: 20px; /* 200% */
        letter-spacing: 0.02px;
      }

      img {
        margin: 0px 20px;
        width: 40px;
        height: 40px;
      }
    }
  }
}

.footer {
  border-top: 1px solid #abafb8;
  padding-top: 10px;
  padding-right: 15px;

  display: flex;
  justify-content: flex-end;
  margin-top: 0px;

  align-items: center;

  Button {
    border-radius: 8px;
    height: 45px;
    color: white;
    // width: 100%;
    background-color: #7043ff;
    display: flex;
    align-items: center;
    img {
      height: 15px;
      width: 15px;
      margin-right: 0px;
      margin-left: 5px;
    }
  }
  Button:hover {
    color: white !important;
    background-color: #7f58ff;
  }
}

.slider {
  width: 100%;
  padding-top: 10px;

  span {
    font-weight: 600;
  }
}

.numberOfFollowups {
  display: flex;
  justify-content: space-between;
}

.generateWithAIButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 129.75px;
}
