.socialBox {
  display: flex;
  padding: 15px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 10px;
  border: 1px solid #dfe1e6;
  background: #fff;
  width: fit-content;
  // cursor: pointer;

  &__logo {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
  }

  &__name {
    overflow: hidden;
    color: #091e42;
    font-feature-settings: 'salt' on;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
  }

  &__button {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 24px;
    border: 1px solid #ebecf0;

    &__dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #50e2ba;
    }

    &__text {
      color: #091e42;
      text-align: justify;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
    }

    &__commingSoon {
      color: var(--Nutrals-500, #97a0af);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.028px;
    }

    &__addAccount {
      color: var(--primary-600-main, #7043ff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.028px;
      cursor: pointer;
    }
  }
}
