.table {
  margin: 20px;
  max-height: 500px;
  height: 500px;

  .plusIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    Button {
      background-color: #7043ff !important;
      //   background-color: #3043ff !important;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white !important;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      img {
        margin: 0px;
      }
    }
  }
}
