.header {
  display: flex;
  flex-direction: column;
  font-size: 16px;

  span {
    font-size: 12px;
    font-weight: 400;
    color: gray;
    margin-bottom: 5px;
  }
  p {
    margin: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.field {
  margin-bottom: 16px;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: bold;
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload > .ant-upload {
  margin-right: 8px;
}

.deleteIcon {
  cursor: pointer;
  color: red;
}
