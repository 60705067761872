.AppIntegration {
  padding: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;

    &__left {
      display: flex;
      align-items: center;
      gap: 24px;

      img {
        width: 40px;
        height: 40px;
      }

      article {
        color: var(--Nutrals-200, #091e42);
        font-family: Roboto;
        font-size: 32px;
        font-weight: 700;
        line-height: 32px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 24px;

      &__switch {
        display: flex;
        align-items: center;
        gap: 12px;

        span {
          color: var(--Natural-600, #97a0af);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.014px;
        }
      }

      &__button {
        border-radius: 24px;
        border: 1px solid var(--Nutrals-600, #dfe1e6);
        padding: 4px 12px;
        color: var(--primary-600-main, #7043ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.028px;
      }

      &__text {
        color: var(--color-blue-03);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.028px;
      }
    }
  }

  &__warning {
    padding-bottom: 32px;
  }

  &__content {
    padding-right: 5px;
    max-width: 1000px;
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    &__rightBtn {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 32px;
      button {
        min-width: 100px;
      }
    }

    &--box {
      border-radius: 8px;
      border: 1px solid var(--Natural-500, #dfe1e6);
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;

      &__title {
        color: #000;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 16px;
      }
    }

    &__left {
      height: 100%;
      &__mandatory {
        padding: 16px 32px 32px;
        border-bottom: 1px solid var(--Natural-500, #dfe1e6);

        article {
          color: var(--Natural-800, #091e42);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .inputContainer {
          padding-top: 15px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      // &__list {
      //   max-height: 300px;
      //   overflow: auto;
      // }

      &__custom {
        padding: 32px;
        width: 100%;
        article {
          color: var(--Natural-800, #091e42);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
  .connectionInfoBox {
    margin-bottom: 32px;
  }
}

.headerList {
  display: flex;
  margin: 16px 0;

  span {
    color: var(--Natural-700, #5e6c84);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:first-child {
      width: 50%;
    }

    &:last-child {
      margin-left: 40px;
    }
  }
}
