.intent-popup {
  width: 436px;

  &__row {
    height: 70px;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid var(--color-gray-03);

    h2 {
      color: var(--color-blue-05);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0025em;
    }

    h3 {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-blue-05);
      letter-spacing: 0.004em;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.proWidget {
  color: var(--color-blue-03);
}

.intent-popup-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin: 0px;
  }
  span {
    font-size: 12px;
    font-weight: 600;
    color: rgb(177, 177, 177);
  }
}
