.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   width: 80%;
  background-color: white;
  height: 100%;
  justify-content: center;
}

.payDuration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    margin-bottom: 5px;
  }
}

.title {
  p {
    font-size: 36px;
    font-weight: 600;
    color: #7043ff;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.credits {
  padding: 5px;
  border-radius: 8px;
  margin-top: 20px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  //   position: fixed;
  //   bottom: 0;
  width: 100%;
  //   background-color: red;
  //   height: 100px;
}
