.contactDetails {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 8px;
  //   margin: 20px 0px;
  font-family: Roboto;

  .rowData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border-radius: 15px;
    }

    .parent_company {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.titleSection {
  color: var(--nutrals-100, #051123);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.024px;
  margin: 0px;
}

.header {
  color: #7043ff;
  font-weight: 600;
}

.content {
  margin-bottom: 20px;
  text-align: center;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  text-align: center;
  Button {
    color: white;
    background-color: #7043ff;
    border-radius: 8px;
    height: 40px;
    font-weight: 600;
  }
  Button:hover {
    color: white !important;
  }
  img {
    padding-right: 5px;
  }
}

.communityHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .communityText {
    display: flex;
    flex-direction: column;
    font-size: 11px;
  }
  img {
    height: 20px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eeeeee;

  .containerItem {
    display: flex;
    align-items: center;
    margin: 5px 0px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 10px 10px 10px 0px;
      //   background-color: #7043ff;
    }
  }
  Button {
    cursor: pointer;
    background-color: white;
    color: #7043ff;
    border-radius: 12px;
    border: 1px solid #7043ff;
    box-shadow: none;
    padding: 3px 10px;
    img {
      padding-right: 5px;
    }
  }
}

.imageStack {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    // background-color: #7043ff;
    border: 2px solid white;
    margin-left: -10px;
  }

  .remainingImages {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    background-color: #7043ff;
    border: 2px solid white;
    color: white;
    height: 26px;
    min-width: 35px;
    width: fit-content;
    border-radius: 18px;
    margin-left: -10px;
  }
}

.imageStackBlur {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    // background-color: #7043ff;
    // border: 2px solid white;
    margin-left: -13px;
  }

  .remainingImages {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    background-color: #7043ff;
    border: 2px solid white;
    color: white;
    height: 26px;
    min-width: 35px;
    width: fit-content;
    border-radius: 18px;
    margin-left: -10px;
  }
}

.connectTelegram {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px 0px 0px 0px;
  font-family: Roboto;
  span {
    font-weight: 600;
  }
  Button {
    background-color: #7043ff;
    font-family: Roboto;
    font-weight: 600;
    color: white;
    border-radius: 8px;
    img {
      padding-right: 5px;
    }
  }
  Button:hover {
    color: white !important;
  }
}

.redClass {
  img {
    border: 2px solid red;
  }
}
