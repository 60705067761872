.border {
  padding: 12px 0;
  border-top: 1px solid black;
  height: 1px;
  opacity: 0.1;
}

.chatTag {
  position: relative;
  width: 100%;

  &__loading {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // z-index: 99;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;

    &__text {
      color: #000;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 16px;
    }
  }

  &__listTag {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 16px;
    max-height: 150px;
    overflow-y: auto;
  }

  &__description {
    color: var(--Natural-700, #5e6c84);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__input {
    width: 100%;
    padding: 8px 16px;
  }

  &__footer {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    color: var(--Natural-700, #5e6c84);
    font-family: Roboto;
    font-size: 14px;
    font-style: italic;
    font-weight: 300;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    gap: 8px;
  }

  &__search {
    margin-top: 8px;
    max-height: 250px;
    position: relative;

    &__item {
      padding: 8px 16px;
      cursor: pointer;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;

      &__text {
        width: 100%;
      }

      &__menu {
        position: relative;

        &__option {
          position: absolute;
          top: 20px;
          border-radius: 8px;
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.15);
          display: flex;
          flex-direction: column;
          width: 189px;
          padding: 8px 16px;
          background: white;
          z-index: 50;
          left: -50px;
        }
      }
    }
  }

  &__edit {
    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 12px 8px;
      cursor: pointer;

      &__text {
        color: var(--Nutrals-100, #051123);
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px; /* 100% */
        letter-spacing: 0.065px;

        &:hover {
          font-family: Roboto;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 13px; /* 100% */
          letter-spacing: 0.065px;
          background: linear-gradient(95deg, #7144ff -1.67%, #b649f3 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
