.title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.002em;
  text-align: left;
}
.subTitle {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-align: left;
  color: #5e6c84;
}

.card {
  height: 90%;
  border: 2px solid #dfe1e6;
}
.card:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(180deg, #7144ff, #b649f3) border-box;
  border: 2px solid transparent;
}
