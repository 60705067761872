$color-replied: #7043ff;
$color-sent: #1b8374;
$color-failed: #ae3f3e;
$color-blocked: black;
$color-pending: #b27701;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 120px;
  color: #051123;
  font-weight: 700;
}

.tag {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(0, 0, 0, 0.02);
  position: relative;
  span {
    // padding-right: 5px;
  }
  img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}

.sent {
  background-color: #e2fff4;
  color: $color-sent;
}
.replied {
  background-color: #f2f0ff;
  color: $color-replied;
}
.failed {
  background-color: #fcf2f2;
  color: $color-failed;
}
.blocked {
  background-color: #f3f4f6;
  color: $color-blocked;
}
.pending {
  background-color: #fff9ee;
  color: $color-pending;
}
