.header {
  background: white;
  img {
    height: 10px;
    // width: 100px;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  font-size: 16px;
  // width: 229px;
  width: 300px;
  height: 32px;
  .nameAndDescription {
    margin-left: 10px;
  }
}

.rightSide {
  display: flex;
  align-items: right;
  justify-content: flex-end;
  font-size: 16px;
  cursor: pointer;
  width: 300px;

  img {
    margin: 0px;
    height: 18px;
    width: 18px;
  }
}
.rightSide:hover {
  color: #7043ff;
}
