.title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.002em;
  text-align: left;
  color: #091e42;
}
.seeMore {
  //styleName: Label/Label large;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.002em;
  text-align: left;
  color: #7043ff;
  cursor: pointer;
}
