.modalBodyStyle {
  max-height: 500px;
  overflow: auto;
}
.mappingListBody {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  gap: 32px;

  .left_input {
    width: 100%;
    border-radius: 10px;
  }

  .right_input {
    width: 100%;
    border-radius: 10px;
  }
  .delete {
    cursor: pointer;
  }
}
