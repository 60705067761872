.container {
  display: flex;
  position: relative;
  width: 160px;
  color: #051123;
}

.tag {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px 0px 0px 8px;
  width: 90px;
  position: relative;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.sent {
  background-color: #e8e3ff;
  border-top: 1px solid #c2b5ff;
  border-bottom: 1px solid #c2b5ff;
  border-left: 1px solid #c2b5ff;
}

.replied {
  background-color: #bde0fe;
  border-top: 1px solid #91d8ff;
  border-bottom: 1px solid #91d8ff;
  border-left: 1px solid #91d8ff;
}

.meetings {
  background-color: #d0f4ea;
  border-top: 1px solid #82e3c1;
  border-bottom: 1px solid #82e3c1;
  border-left: 1px solid #82e3c1;
}

.blocked {
  background-color: #fdc4c4;
  border-top: 1px solid #ff6f6f;
  border-bottom: 1px solid #ff6f6f;
  border-left: 1px solid #ff6f6f;
}

// img {
//   width: 20px;
//   height: 20px;
//   margin-right: 8px;
// }

.percentage {
  // margin-right: 8px;
  text-align: center;
  font-weight: 700;
}

.number {
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  min-width: 45px;
  border-radius: 8px;
  padding: 4px 8px;
  text-align: center;
}

.sent-tag {
  background-color: #f4f2ff;
  border: 1px solid #c2b5ff;
}

.replied-tag {
  background-color: #def3ff;
  border: 1px solid #91d8ff;
}

.meetings-tag {
  background-color: #d7fbee;
  border: 1px solid #82e3c1;
}

.blocked-tag {
  background-color: #ffd8d8;
  border: 1px solid #ff6f6f;
}
