.rightSide {
  Button {
    background-color: #7043ff;
    color: white;
    border-radius: 8px;
    height: 40px;
  }
  Button:hover {
    background-color: #8964ff;
    color: white !important;
  }
}

.continue {
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
    height: 10px;
  }
}

.launch {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    height: 15px;
  }
}

.leftSide {
  text-align: start;
  Button {
    background-color: #7043ff;
    color: white;
    border-radius: 8px;
    height: 40px;
  }
  Button:hover {
    background-color: #8964ff;
    color: white !important;
  }
}
