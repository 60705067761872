.groupsModal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .modalLayout {
    background: none;
  }

  .modalHeader {
    background-color: #fff; // Your header color
    padding: 16px;
    font-size: 24px; // Adjust as per your design
  }

  .modalContent {
    padding: 16px;
    background-color: #f0f2f5; // Your content background color
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  align-items: center;
  gap: 20px;
}

.filter {
  background: white;
}

.contentStyle {
  min-height: 120px;
  background-color: white;
  padding: 0px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    // text-align: center;

    img {
      margin-top: 20px;
    }

    p {
      margin: 10px 0px 8px 0px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }

    span {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .footer {
      display: flex;
      margin-bottom: 20px;
      Button {
        border-radius: 20px;
        width: 100px;
        text-align: center;
        margin: 0px 5px;
        padding: 0px 5px;

        &:first-child {
          background-color: #ccc; // Style for the Close button
          color: black;
        }

        span {
          font-size: 14px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.switches {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  align-items: left !important;
}

.switchItem {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  // margin: 10px 0px;
  span {
    margin: 0px !important;
  }
  Button {
    width: 10px !important;
    margin-right: 5px;
  }
}
