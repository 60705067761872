.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content !important;
  max-height: 100%;
  flex-grow: 1;
}

.containerText {
  margin: 25px 35px;
  text-align: center;
  height: 20%;
}

.containerText p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin: 10px;
}

.containerText section {
  font-size: 14px;
}

.searchIcon {
  color: '#d9d9d9';
  margin-right: '2px';
}

.options {
  overflow: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-height: 125px;
}

.searchContainer {
  height: 10%;
  width: 80%;
}

.groupsContainer {
  height: 30%;
  width: 100%;
}

.contactsContainer {
  height: 30%;
  width: 100%;
}

.resultsContainer {
  height: 70%;
  width: 100%;
}
