.topMenu {
  font-size: 16px;
  color: var(--color-white);

  img {
    width: 25px;
    height: 25px;
  }
}

.badge {
  border: 1px solid #7143ff;
  color: #7143ff;
  background: white;
  font-family: 'Roboto';
  font-weight: 700;
  border-radius: 10px;
  font-size: 10px;
  padding: 3px;
  margin-top: 15%;
  margin-right: 10%;
}

.newBadge {
  // border: 1px solid #7143ff;
  color: #7143ff;
  background: white;
  font-family: 'Roboto';
  font-weight: 700;
  border-radius: 100px;
  font-size: 8.5px;
  padding: 3px;
  margin-top: 7%;
  margin-right: 7%;
}
