.mobile-layout {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-blue-05);

  h1 {
    margin: 0;
    text-align: center;
    letter-spacing: 0.037em;
    font-weight: 600;
    font-size: 27px;
  }

  span {
    text-align: left;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.03em;
  }
}
