.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  img {
    width: fit-content;
    height: fit-content;
  }
}
.node {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  color: black;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0 0 20px 0px rgb(0, 0, 0, 0.15);
  //   margin: 0px 45px;
  img {
    margin-left: 5px;
    margin-right: 10px;
    width: 60px;
  }
}

.nodeSnakeEffect {
  position: relative;
  --border-angle: 0.5turn;
  --main-bg: conic-gradient(from var(--border-angle), white, white 54%, white 60%, white 95%);
  background: var(--main-bg);
  background-position: center center;
  animation: bg-spin 1.5s linear forwards;
}

.nodeSnakeEffect::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: conic-gradient(from var(--border-angle), transparent 25%, #cb49ef, #7043ff 99%, transparent);
  z-index: -1;
  border-radius: 16px;
}

@keyframes bg-spin {
  to {
    --border-angle: 1.5turn;
  }
}

.node.multi {
  background-color: #ff6347 !important;
}

.node:hover {
  background-color: #f9f7ff !important;
}

.node:focus-visible {
  background-color: #ff6347;
}

.within {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 20px;
  span {
    font-size: 12px;
  }
}

.wait {
  span {
    margin-left: 5px;
  }
}

@property --border-angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 1turn;
}
