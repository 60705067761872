.intent-signals-paywall {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  // padding: 1rem;

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
  }

  p {
    width: 648px;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: 0.005em;
    text-align: center;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: 0.005em;
  }
}
