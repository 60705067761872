.actions {
  display: flex;
  line-height: 2.5;
  align-items: center;

  img {
    margin-right: 10px;
    height: 18px;
    width: 18px;
    justify-content: space-between;
  }
}

.actionsButton {
  display: flex;
  align-items: center;
  color: black;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  cursor: pointer;
}
