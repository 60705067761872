.savedFilters {
  p {
    font-weight: 600;
    margin-top: 0px;
  }
}

.container {
  margin: 0px;
  padding: 0xp 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ebecf0;
  border-radius: 8px;
  min-height: 304px;
  max-height: 304px;
  min-width: 450px;
  overflow-y: auto;
}

// .container::-webkit-scrollbar {
//   width: 0px !important;
// }

.header {
  display: flex;
  align-items: center;
  margin: 10px 0px 0px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebecf0;

  img {
    width: 20px;
    margin-right: 10px;
  }

  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #7043ff;
  }
}

.section {
  cursor: pointer;

  //   border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  border-bottom: 1px solid #ebecf0;

  .rightArrow {
    width: 15px;
    height: 15px;
  }

  .item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
  }

  span {
    font-size: 12px;
    color: #777;
  }
}

.section:hover {
  background-color: #f9f9f9;
}

.lastSection {
  border-bottom: none;
}

.loading {
  height: 324px;
}

.noFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 302px;
  p {
    font-weight: 400;
    color: #777;
  }
}
