$color-outreach: #7043ff;
$color-followup: #7043ff;
// $color-failed: #ae3f3e;
// $color-blocked: black;
// $color-pending: #b27701;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 150px;
  color: #051123;
  font-weight: 700;
  font-size: 13px;
}

.tag {
  display: flex;
  align-items: center;
  min-width: 145px;
  padding: 4px 8px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 1px rgb(0, 0, 0, 0.02);
  position: relative;
  span {
    // padding-right: 5px;
  }
  img {
    width: 25px;
    // height: 25px;
    margin-right: 5px;
  }
}

.followup {
  //   background-color: #f2f0ff;
  //   color: $color-followup;
  background-color: #f2f0ff;
  color: #7043ff;
}
.outreach {
  background-color: #f2f0ff;
  color: $color-outreach;
}
.message {
  background-color: #f2f0ff;
  color: $color-outreach;

  img {
    width: 30px !important;
  }
}
.connect {
  background-color: #f2f0ff;
  color: $color-outreach;

  img {
    width: 30px !important;
  }
}
.follow {
  background-color: #f2f0ff;
  color: $color-outreach;

  img {
    width: 30px !important;
  }
}
.like {
  background-color: #f2f0ff;
  color: $color-outreach;

  img {
    width: 30px !important;
  }
}
.comment {
  background-color: #f2f0ff;
  color: $color-outreach;

  img {
    width: 30px !important;
  }
}
// .failed {
//   background-color: #fcf2f2;
//   color: $color-failed;
// }
// .blocked {
//   background-color: #f3f4f6;
//   color: $color-blocked;
// }
// .pending {
//   background-color: #fff9ee;
//   color: $color-pending;
// }
