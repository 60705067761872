.company-details-paywall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  padding: 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9999;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  p {
    width: 300px;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: 0.005em;
    text-align: center;
  }
}

.blur {
  position: relative;
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
  min-height: 300px;
}
