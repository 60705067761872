.container {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55vh;
}

.centeredContent {
  text-align: center;
}

.container p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
}

.container section {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.05;
  margin: 0px 0px 20px 0px;
}

.container h5 {
  margin: 30px 0px 5px 0px;
}

.container h1 {
  font-size: 96px;
}

.form-input {
  height: 45px;
}

.codeInputs {
  display: flex;
  justify-content: center;
}

.verificationInput {
  width: 87px;
  height: 87px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0 5px;
  text-align: center;
}

.resendButton {
  margin: 20px 0px 20px 0px !important;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: black;
  border-radius: 5px;
  text-align: center;
}

.resendButton span {
  cursor: pointer;
}
