.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 5px;

  p {
    font-weight: 700;
    margin: 5px 0px;
  }

  .points_used {
    color: var(--Nutrals-100, #051123);
    /* Title/Title medium */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;
  }

  .points_used_body {
    color: var(--Nutrals-300, #5e6c84);
    /* Body/Body small */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    letter-spacing: 0.04px;
  }

  .switchItem {
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }

  .switchLabel {
    margin-left: 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 600;
    // margin-top: 10px;
  }
}

.loading {
  display: flex;
  align-items: center;

  min-height: 534px;
}

.customFileInput {
  width: 102px;
  height: 44px;
  color: transparent;
}
.customFileInput::-webkit-file-upload-button {
  visibility: hidden;
}
.customFileInput::before {
  content: 'Browse';
  color: black;
  display: flex;
  font-weight: 700;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 8px;
  height: 45px;
  color: white;
  border: 0.5px solid #7043ff;
  color: #7043ff;
  height: 42px;
  width: 100px;
  justify-content: center;
  align-items: center;
}
.customFileInput:hover::before {
  border: 0.5px solid #7043ff;
}
.customFileInput:active {
  outline: 0;
}
.customFileInput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.errorMessage {
  margin: 20px 5px;
  color: red;
}
