.month-switcher {
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  display: flex;
  padding: 0.28571rem 0.85714rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 46px;
  border: 1px solid var(--color-gray-04);
  min-width: 250px;
  height: 50px;

  span {
    font-size: 1.14286rem;
    font-weight: 500;
    line-height: 1.14286rem;
    letter-spacing: 0.00229rem;
  }
}
