.disabled {
    cursor: not-allowed;
    color: #bdbdbd;
  
    svg {
      font-size: 18px !important;
    }
  }
  
  .exclude {
    color: #e8287c;
  
    svg {
      font-size: 18px !important;
    }
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  