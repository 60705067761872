.campaignFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 855.5px;
  margin-bottom: 30px;
  // margin-left: 35px;
  // margin-right: 35px;
  // margin-top: 30px;

  span {
    font-weight: 600;
  }

  Button {
    margin-left: 10px;
    border-radius: 8px;
  }

  .rightSide {
    Button {
      background-color: #7043ff;
      color: white;
      border-radius: 24px;
      margin: 0;
    }
    Button:hover {
      background-color: #9676ff;
      color: white !important;
    }
  }
}

.toggleSwitch {
  position: absolute;
  right: 35px;
  top: 180px;
}

.newCampaignButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    margin-right: 5px;
  }
}
