.accountStatus {
  img {
    margin-left: 45px;
  }
}

.handle {
  font-weight: 600;
  // cursor: pointer;
  filter: blur(4px);
}

.full_name {
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 5px;
  }
}
