.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
  background-color: white;
  border-radius: 8px;
  margin: 20px;
  line-height: 50px;
}

.centered {
  text-align: center;

  img {
    height: 40px;
    width: 40px;
  }

  p {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px !important;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
  }

  span {
    font-size: 14px;
    font-weight: 600px;
    color: #5e6c84;
  }
}

.buttons {
  line-height: 0px;
  span {
    color: white !important;
  }

  Button {
    border-radius: 8px;
    height: 40px;
    width: 260px;
    margin: 0px 5px;
    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      label {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #40c1b9;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 3px;
      }
    }
  }

  .buttonCSV {
    width: 170px;
  }

  img {
    height: 16px;
    width: 16px;
    margin-right: 5px !important;
  }
}

.comingSoon {
  position: absolute;
  right: 0;
  top: 0;

  img {
    height: 20px;
    width: 40px;
  }
}

.btn {
  background-color: #6a5acd;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  position: relative;
  font-size: 16px;
  cursor: pointer;
}

.btn-with-ribbon {
  position: relative;
  display: inline-block;
}

.ribbon {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: green;
  color: white;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
}