.tech-stack-container {
    width: 360px;
    background-color: var(--color-gray-03);
    box-sizing: border-box;
    animation: slideIn 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    z-index: 0;

    &__category {
        max-height: calc(100% - 3rem);
        height: 100%;
        overflow: auto;
        background-color: var(--color-white);
        width: 327px;
        border-radius: 16px;

        h1 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: var(--color-blue-05);
            margin: 20px 24px;
        }

        &__divider {
            height: 1px;
            width: 100%;
            background-color: var(--color-white-04);
        }

    }

}