.animationContainer {
  text-align: center;
  border-radius: 4px;
  width: 100%;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: auto;
  }
}
