.contentStyle {
  background-color: white;
  padding: 0px;
  .container {
    min-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    padding: 28px 10px;

    img {
      margin-bottom: 16px;
    }
    h3{
      color: #000;
      text-align: center;

      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; 
      margin: 0;
    }

    p {
      color: #5E6C84;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 8px
    }

 
    Button {
      border-radius: 24px;
      height: 48px;
      width: 100%;
      text-align: center;
      border: 1px solid #DFE1E6;
      color: #5E6C84;

      span {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }
    .btnRed{
      background: #FF5C5C;
      border: 1px solid #FF5C5C;
      color: white;
    }
  }
}
