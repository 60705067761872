.unlinkModalCustomized {
  .contentCustomized {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 8px;
    padding-top: 12px;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 32px;
    }
    label {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
    }
    span {
      padding-top: 16px;
      padding-bottom: 24px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.08px;
      color: var(--color-gray-02);
    }
  }
  .footer {
    padding-bottom: 12px;
    Button {
      width: 100%;
      height: 40px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.028px;
      &:hover {
        opacity: 0.7;
      }
      &.yesBtn {
        background-color: #ff5c5c;
        color: #fff;
        border-color: #ff5c5c;
      }
      &.cancelBtn {
        color: var(--color-gray-02);
        border-color: var(--color-gray-02);
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}
