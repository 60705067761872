.company-details-paywall-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--color-white);
  //   padding: 0.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9999;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 90%;
  //   min-height: 300px;

  p {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.005em;
    text-align: center;
    margin: 10px 0px;
  }
  img {
    height: 50px;
  }

  Button {
    background-color: #7043ff !important;
    color: white !important;
    border-radius: 8px;
  }
}

.blur-filters {
  position: relative;
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
  min-height: 300px;
}

.paywall-pricing-plans-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.pricing-plan-item-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    font-size: 16px;
    font-weight: 500;
  }
  img {
    height: 20px;
    margin-right: 5px;
  }
}
