.container {
  display: flex;
  flex-direction: column;

  p {
    font-weight: 700;
    color: gray;
    margin: 5px 0px;
  }

  .header {
    border-top: 1px solid #ebecf0;
    padding-top: 20px;
    margin-bottom: 20px;
    span {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .listsHeader {
    font-weight: 600;
    // margin-bottom: 20px;
    padding: 20px 0px;
    border-top: 1px solid #ebecf0;
    border-bottom: 1px solid #ebecf0;
  }

  .listsContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 500px;
  }

  .list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;

    .checkbox {
      display: flex;
      align-items: center;

      p {
        color: black;
        font-weight: 600;
        margin: 0 10px;
      }
    }
  }
}

.button {
  Button {
    margin-top: 10px;
    border-radius: 8px;
    width: 200px;
    height: 45px;
    color: white;
    background-color: #7043ff;
    cursor: pointer;

    &:hover {
      color: white !important;
      background-color: #7f58ff;
    }
  }
}

.enrich {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 10px;
    font-weight: 600;
    font-size: 15px;
  }
}
