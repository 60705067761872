.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 80%;
  background-color: var(--Nutrals-800, #f3f4f6);
  height: 100%;
  justify-content: center;
  overflow: auto;
}

.title {
  p {
    font-size: 36px;
    font-weight: 600;
    color: #7043ff;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}

.credits {
  padding: 5px;
  border-radius: 8px;
  // margin: 20px 0px 20px 0px;
  width: 930px;
  background-color: white;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  //   position: fixed;
  //   bottom: 0;
  width: 100%;
  //   background-color: red;
  //   height: 100px;
}

.backButton {
  position: absolute;
  top: 45px;
  left: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    height: 15px;
  }
  span {
    padding-left: 5px;
    font-weight: 600;
  }
}
