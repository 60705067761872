.custom-collapse {
  background-color: #f9fafb;
  border: none;
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #ebecf0;
    // width: 100%;
    button {
      cursor: pointer;
      margin-left: 10px;
      background-color: #7043ff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: 0.002em;
      color: #ffffff;
      border-radius: 100px;
      border: 0;
      padding: 0 17px;
    }

    button:hover {
      color: white !important;
      background-color: #8763ff;
    }
    .reset {
      cursor: pointer;
      padding: 0px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.002em;
      color: #7043ff;
    }
  }
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: default;
  // overflow-y: auto;
  .leftSide {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .headerText {
      color: #97a0af;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.024px;
      &__active {
        color: #091e42;
        font-weight: 700;
      }
    }
  }
  .rightSide {
    display: flex;
    align-items: center;
    span {
      color: #7043ff;
      font-weight: 600;
    }
    .completeIcon {
      margin-left: 12px;
    }
  }
}
