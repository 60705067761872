.resetPasswordModal {
  .ant-form-item-label {
    text-align: left;
  }

  .ant-modal-footer {
    border-top: none;
    padding-top: 0;

    .ant-btn {
      margin-right: 8px;
    }
  }
}

.container {
  padding: 0px 15px;
}

.header {
  padding-top: 15px;
  p {
    margin: 10px 0px 8px 0px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.footer {
  Button {
    border-radius: 8px;
    width: 160px;
    text-align: center;
    margin: 0px 5px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
