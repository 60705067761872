.companyDetails {
  .ant-drawer-header {
    margin-bottom: 24px;
  }

  .ant-drawer-body {
    background: var(--nutrals-800, var(--color-gray-03));
    padding-top: 16px !important;
    .container {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
      align-self: stretch;
      border-radius: 8px;
      background: var(--color-white);
      margin-bottom: 16px;

      .ant-collapse {
        width: 100%;
        .ant-collapse-header {
          padding: 0px !important;
          .ant-collapse-expand-icon {
            .anticon.anticon-right.ant-collapse-arrow::after {
              transform: rotate(0deg);
            }
            .anticon.anticon-right.ant-collapse-arrow svg {
              display: none;
            }
            .anticon.anticon-right.ant-collapse-arrow::after {
              content: url('../../../../common/assets/svg/arrow_section.svg') !important;
            }
          }
        }
        .ant-collapse-header[aria-expanded='true']
          .ant-collapse-expand-icon
          .anticon.anticon-right.ant-collapse-arrow::after {
          transform: rotate(-180deg);
        }
        .ant-collapse-header-text {
          text-align: left;
          color: var(--nutrals-100, var(--color-gray-06));
          font-family: 'Roboto';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.024px;
        }
      }

      .ant-collapse-content-box {
        padding: 0px;
        text-align: left;
      }
    }
  }

  .reportDetails {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.048px;
      color: var(--color-gray-01);
      margin: 0px;
    }

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.024px;
      color: var(--color-gray-06);
    }
  }

  .socialImages {
    display: table;
    margin-top: 12px;

    .item {
      display: table-cell;
      float: left;

      a,
      span {
        width: auto;
        display: block;

        img {
          float: left;
          width: 30px;
          height: 30px;
        }

        span {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.005em;
          color: var(--color-black);
          padding: 10px 22px 0px 6px;
          letter-spacing: 0.052px;
        }
      }
    }
  }
}

.headerRate {
  height: 20px;
  margin-top: -5px;
  margin-bottom: 0px;
  color: transparent;
}

.headerRate:hover .rateData {
  display: block !important;
}

.headerRate:hover .rateData {
  display: block !important;
}

.rateMargin {
  margin-bottom: -15px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-blue-03) !important;
}

.tabsSection {
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;

  .ant-tabs-nav {
    position: sticky;
    top: -16px;
    z-index: 1;
    background-color: var(--color-white);
    width: 100%;
  }

  .tabSection {
    text-align: left;
    padding: 0px 24px 0px 24px;
    .title {
      color: var(--nutrals-100, var(--color-gray-06));
      font-family: 'Roboto';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.024px;
    }

    .rowCustom {
      margin-bottom: 16px;
      margin-top: 21px;
    }

    .title:hover .rateData {
      display: block !important;
    }

    .card {
      border-radius: 8px;
      background: var(--color-gray-07);
      padding: 16px;
      .left {
        .title {
          color: var(--nutrals-100, var(--color-gray-06));
          font-family: 'Roboto';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.048px;
          display: block;
          padding-top: 3px;
        }
        .value {
          color: var(--nutrals-100, var(--color-gray-06));
          font-family: 'Roboto';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.024px;
        }
      }
      .right {
        float: right;
        padding-top: 12px;
        position: absolute;
        top: 25px;
        right: 11px;
      }
      .right_tvl {
        float: right;
        padding-top: 12px;
        position: absolute;
        top: 25px;
        right: 11px;
      }
    }

    .auditsContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      .item {
        background-color: var(--color-gray-07);
        border-radius: 8px;
        padding: 16px;

        .leftAudit {
          .logo {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            float: left;
            margin-right: 6px;
          }
          span {
            color: var(--color-black);
            font-family: 'Roboto';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.014px;
            padding-top: 2px;
          }
        }
        .rightAudit {
          width: 100%;
          text-align: right;
          .image-container {
            position: relative;
            display: inline-block;
            img {
              margin-bottom: -7px;
            }
          }

          .text-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .text-overlay p {
            color: var(--color-white);
            font-size: 13px;
            font-weight: 700;
            font-style: normal;
            text-align: center;
            font-family: 'Roboto';
            padding-top: 2px;
          }

          .audit_amount {
            float: right;
          }
        }
      }
    }
  }
}

.blueText {
  color: var(--color-blue-03);
  font-family: 'Roboto';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-top: 18px;
  margin-bottom: 18px;

  span {
    color: var(--nutrals-500, var(--color-gray-01));
    text-align: center;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.06px;
    padding-left: 6px;
  }
}

.geoImage {
  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: left;
  }
  span {
    color: var(--color-black);
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding-left: 8px;
  }
}

.noRadius {
  border-radius: 0px !important;
}

.techStackSearch {
  margin-bottom: -10px;
  margin-top: 16px;
}

.tabContent {
  border-bottom: 1px solid var(--nutrals-800, var(--color-gray-03));
  padding-top: 18px;
  padding-bottom: 18px;
  .left {
    span {
      color: var(--color-black);
      font-family: 'Roboto';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0.014px;
      text-align: left;
    }

    .flexDisplay {
      display: flex;
    }

    img {
      width: 28px !important;
      height: 28px !important;
      float: left;
      border-radius: 50%;
      margin-right: 12px !important;
    }

    .fundingAmount {
      color: var(--color-black);
      font-family: 'Roboto';
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      display: block;
    }

    .fundingType {
      color: var(--color-black);
      font-family: 'Roboto';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.014px;
    }
  }
  .right {
    .tsList {
      color: var(--nutrals-100, var(--color-gray-06));
      font-family: 'Roboto';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.048px;
      max-width: 225px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      text-align: right;
    }

    .fundingDate {
      color: var(--nutrals-100, var(--color-gray-06));
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      padding-top: 20px;
      letter-spacing: 0.048px;
      text-align: right;
      display: block;
    }
  }
}

.similarCompanies {
  margin-bottom: 20px;
  cursor: pointer;

  .columnTitle {
    color: var(--nutrals-100, var(--color-gray-06));
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.048px;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    float: left;
  }
  .companyName {
    color: var(--color-black);
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.014px;
    padding-left: 12px;
  }

  .percentValue {
    color: var(--nutrals-100, var(--color-gray-06));
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.014px;
  }
}

.showInContacts {
  border: 1px solid var(--color-gray-04);
  border-radius: 16px;
  max-width: 177px;
  height: 34px;
  text-align: center;
  margin: 0px auto;
  margin-top: 28px;
  cursor: pointer;
  padding: 0px 20px;
  color: var(--color-blue-03) !important;

  display: flex;

  img {
    margin: auto 0px auto auto;
  }

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 26px;
    letter-spacing: 0.002em;
    color: var(--color-blue-03);
    margin: auto;
    margin-left: 8px;
  }
}

.spaceCountryRow {
  margin-bottom: 30px;
}

.customProgress .ant-progress-bg {
  background-color: #7043ff !important;
}

.customProgress .ant-progress-inner {
  background-color: #f2f0ff !important;
}

.customProgress .ant-progress-text {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  padding-top: 4px;
  text-align: right;
  width: auto;
  display: block;
}

.bottomMargin {
  margin-bottom: 24px;
}
.blurImg {
  width: 100%;
}

.tabSection .ant-btn-default {
  border-color: #d9d9d9 !important;
}

.socialContacts {
  text-align: left;
  text-align: left;
  padding: 5px;
  position: absolute;
  top: -35px;
  left: 10px;
}

.socialContacts img {
  width: 25px !important;
  height: 25px !important;
  margin: 5px;
}

.subTitle {
  display: flex;
  flex-direction: row;
}
.subTitle span {
  color: var(--nutrals-300, #5e6c84);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.026px;
  margin: 0px 2px;
}
.subTitle span img {
  width: 14px !important;
  height: 14px !important;
  margin: 5px;
}

.tagListParent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
