.container {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  min-height: 400px;
  background-color: white;
  overflow: auto;

  .title {
    margin-top: 15px;
    margin-bottom: 25px;

    &__text {
      margin-bottom: 15px;
    }
  }
}
