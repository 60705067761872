.thankYouContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.upgrade {
  display: flex;
  flex-direction: column;
  span {
    margin-top: 15px;
    color: #7043ff;
    font-weight: 600;
  }
}

.result {
  p {
    font-size: 38px;
    margin: 0;
  }
  section {
    font-size: 28px;
  }
}
