.selectTimeContainer {
  display: flex;
  align-items: center;

  div {
    align-items: center;
    text-align: center;
    padding: 0px !important;
    span {
      font-size: 16px;
      font-weight: 600 !important;

      padding-inline-end: 0px !important;
    }
  }
}

.dash {
  margin: 0px 0px 0px 5px;
}

.applyToAll {
  color: #7043ff;
  font-weight: 600;
  cursor: pointer;
  padding-right: 5px;
}
