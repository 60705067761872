.container {
  margin: 0px 22px 20px 22px;
  text-align: left;
  flex-grow: 1;
}

.container p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.container section {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.05;
  text-align: center;
}

.container h5 {
  margin: 25px 0px 5px 195px;
}

.form-input {
  height: 45px;
}

.prefix {
  width: 125px !important;
}
