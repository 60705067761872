.container {
  // display: flex;
  // justify-content: center;
  // width: 40px;
  // max-width: fit-content;
  padding: 2px 6px;
  border-radius: 6px;
}

.running {
  background-color: #19bcfe1a;
  color: #19bcfe;
}

.error {
  background-color: #ff5c5c1a;
  color: #ff5c5c;
}

.pending {
  background-color: #ebecf0;
  color: #97a0af;
}

.pause {
  background-color: #ebecf0;
  color: #97a0af;
}

.draft {
  background-color: #ebecf0;
  color: #97a0af;
}

.awaiting {
  background-color: #19bcfe1a;
  color: #19bcfe;
}

.warming {
  background-color: #19bcfe1a;
  color: #19bcfe;
}

.completed {
  background-color: #02c3ab1a;
  color: #02c3ab;
}
