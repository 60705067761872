.item {
  display: table-cell;
  float: left;

  a {
    width: auto;
    display: block;

    img {
      float: left;
      width: 30px;
      height: 30px;
    }

    span {
      font-family: 'Roboto';
      font-size: 13px;
      line-height: 13px;
      display: flex !important;
      align-items: center;
      text-align: center;
      padding: 10px 22px 0px 6px;
      font-weight: bold;
    }
  }
}
