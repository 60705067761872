.loading {
  background-color: white;
  min-height: 68px;
  display: flex;
  align-items: center;
}

.headerStyle {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0px;
  border-bottom: 1px solid #ebecf0;
  img {
    width: 20px;
    margin-right: 10px;
  }

  h1 {
    font-size: 14px;
    font-weight: bold;
    color: #7043ff;
  }
}

.contentStyle {
  min-height: 120;
  background-color: white;
  // padding: 0px 0px 30px 0px;
  text-align: center;
  max-height: 400px;
  overflow-y: auto;

  .container {
    padding: 0px 20px;
  }

  .header {
    margin-bottom: 10px;
  }

  .title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: 0px 0px 10px 0px;
  }

  .description {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }

  .switchContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .switchItem {
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }

  .switchLabel {
    margin-left: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
  }

  .terms {
    margin-top: 125px;
    font-size: 12px;
    color: #999;
  }
}

.footerStyle {
  padding: 16px 16px 0px 16px;
  background-color: white;
  border-top: 1px solid #ebecf0;

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .button {
      background-color: #7043ff;
      color: white;
      border-radius: 8px;
    }

    .button:disabled {
      background-color: #adadad;
      color: white;
      border-radius: 8px;
    }

    .centeredText {
      display: flex;
      margin-left: 25px;
      font-size: 12px;
      font-weight: 600;
      color: #5e6c84;

      img {
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .back {
      cursor: pointer;
      font-family: Roboto;
      color: black;
      font-size: 14px;
      font-weight: 800;

      img {
        margin-right: 5px;
      }
    }
  }
}

.addNewContainer {
  display: flex;
  Input {
    width: 80%;
    border-radius: 8px 0px 0px 8px;
    border-right: none;
    border-color: #7043ff;
  }
}

.rightButton {
  Button {
    border-radius: 0px 8px 8px 0px !important;
    border-left: none;
    background-color: #7043ff;
    color: white;
    font-weight: 600;
  }
  Button:hover {
    color: white !important;
    background-color: #835dff;
  }
}

.createNew {
  padding: 10px 0px 10px 0px;
  // border-bottom: 1px solid #ebecf0;
  p {
    font-weight: 600;
    font-size: 14px;
  }
  Button {
    border-radius: 8px;
    width: 100%;
  }
}

.rowListItem {
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 20px 0px;
  span {
    font-weight: 600;
    padding-left: 20px;
  }
}
.selectedList {
  background-color: #f2f0ff;
  box-shadow: inset 0px 0px 0px 1px #7043ff;
}

.rowListItem:hover {
  background-color: #f9f9f9;
}

.noHover {
  background-color: #f2f0ff !important;
}
