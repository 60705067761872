.sider {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    article {
      color: var(--Nutrals-100, #051123);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.024px;
    }

    button {
      display: flex;
      height: 40px;
      width: 60px;
      padding: 4px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--primary-600-main, #7043ff);
      color: white;

      &:hover {
        color: white !important;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    article {
      color: var(--Natural-800, #091e42);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.07px;
      margin-bottom: 8px;
    }
  }
}
