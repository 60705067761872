.loading {
  background-color: white;
  min-height: 305px;
  min-width: 553px;
  display: flex;
  align-items: center;
}

.headerStyle {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0px;
  border-bottom: 1px solid #ebecf0;
  img {
    width: 20px;
    margin-right: 10px;
  }

  h1 {
    font-size: 14px;
    font-weight: bold;
    color: #7043ff;
    margin: 0;
  }
}

.contentStyle {
  min-height: 120;
  background-color: white;
  padding: 30px 0px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    img {
      margin-top: 80px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0px;
    }

    span {
      font-size: 14px;
      color: #666;
      margin-bottom: 80px;
    }
  }
}

.footerStyle {
  padding: 16px 16px 0px 16px;
  background-color: white;
  border-top: 1px solid #ebecf0;

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .button {
      background-color: #7043ff;
      color: white;
      border-radius: 8px;
    }

    .centeredText {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: #5e6c84;
      min-width: fit-content;

      img {
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .back {
      cursor: pointer;
      font-family: Roboto;
      color: black;
      font-size: 14px;
      font-weight: 800;

      img {
        margin-right: 5px;
      }
    }
  }
}
