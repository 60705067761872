.channelsContainer {
  width: 100%;
  display: flex;
  padding: 6px;
  border-radius: 40px;
  background-color: #f2f0ff;
  justify-content: center;

  .iconWrapper {
    border: 1px solid #7043ff;
    width: 26px;
    height: 26px;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    /* Add this to your CSS file */
  }

  .linkedin {
    img {
      filter: invert(81%) sepia(100%) saturate(11319%) hue-rotate(267deg) brightness(99%) contrast(106%);
    }
  }

  img {
    padding: 2px;
    height: 20px;
    width: 20px;
    margin: 4px;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
  background: #e3d1ff;
}
