.container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  //   padding: 10px;
}

.icon {
  //   width: 30px;
  //   height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  //   gap: 5px;
  img {
    width: 22px !important;
    height: 22px !important;
    transition: filter 0.3s ease-in-out;
    margin-right: 4px;
  }

  &.active {
    img {
      cursor: pointer;
    }
  }

  &.inactive {
    img {
      //   filter: grayscale(100%);
      filter: brightness(1.2) grayscale(100%);
      opacity: 0.8;
    }
  }
}

.comingSoonOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: gray;
    filter: drop-shadow(0px 0px 10px white);
  }
}
