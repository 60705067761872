@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nodeEnterAnimation {
  animation-name: fadeInUp;
  animation-fill-mode: forwards;
  animation-duration: 0.5s; /* Control the speed of the animation */
  /* The animation-delay will be set dynamically in the style attribute */
}
