.actionsBar {
  margin: 10px 20px -20px 40px;
}

.optionsList {
  padding-left: 24px;
  margin-top: 35px;
  display: flex;
  gap: 0.5rem;

  .btnSwitch {
    height: 32px;
    background: var(--color-blue-03);
    border-radius: 16px;
    display: block;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;

    img {
      margin-top: 8px;
      float: left;
    }

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: 0.002em;
      color: var(--color-white);
      padding-left: 10px;
    }
  }

  .export {
    padding: 4px 0px;
    width: 73px;
    height: 32px;
    border: 1px solid var(--color-gray-04);
    border-radius: 16px;
    display: flex;
    cursor: pointer;
    float: left;
    margin-right: 20px;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.002em;
      color: var(--color-blue-03);
      padding: 0px 16px;
    }
  }
}

.companiesFooter {
  position: sticky;
  bottom: 0px;
  height: 60px;
  min-height: 60px;
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;

  .verticalLine {
    border-left: 2px solid var(--color-gray-04);
    height: 25px;
    width: 0.5px;
    float: left;
    padding-right: 12px;
    margin-left: 12px;
  }
}

.fancyDrawer {
  .drawerTitleContact {
    p {
      color: #000 !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      line-height: 24px !important;
      letter-spacing: 0.024px !important;
    }
  }
}

.subTitle {
  display: flex;
  flex-direction: row;
  span {
    color: var(--nutrals-300, #5e6c84);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.026px;
    margin: 0px 2px;
    img {
      width: 14px !important;
      height: 14px !important;
      margin: 5px;
    }
  }
}

.centerAlign {
  text-align: center;
  margin: 0px auto;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.socialContacts {
  text-align: right;
  text-align: right;
  padding: 5px;
  position: absolute;
  top: -35px;
  right: 10px;
  img {
    width: 25px !important;
    height: 25px !important;
    margin: 5px;
  }
}

.actions {
  text-align: left;
  float: right;
  position: absolute;
  top: -25px;
  left: 0;
  margin-left: 10px;
  z-index: 1000;
  align-items: left;
  img {
    width: 20px !important;
    height: 20px !important;
    margin: 5px 0px 5px 0px;
    border-radius: 0 !important;
    border: none !important;
  }
}

.tagListParent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.addToMappingList {
  background: var(--primary-600-main, var(--color-blue-03));
  color: var(--nutrals-1000, var(--color-white)) !important;
  font-size: 14px !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500 !important;
  line-height: 25px;
  letter-spacing: 0.028px;
  width: 95%;
  border-radius: 5px !important;
  margin: 0px 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 5px 20px;
  span {
    width: 300px;
  }
}

.mappingListModal {
  width: 750px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.customclass {
  background-color: green !important;
}

.hasTelegramImage {
  border-radius: 50%;
  img {
    margin-top: 0px !important;
  }
}

.numberCircle {
  img {
    border: none !important;
    height: 18px !important;
    width: 18px !important;
    border-radius: 0% !important;
    margin-right: 2px;
  }
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 700;
  color: white;
}

.myContact {
  display: flex;
  width: 100%;
  flex: 1;
  max-height: 100vh;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfe1e6;
    padding: 24px;

    &__left {
      color: var(--Nutrals-200, #091e42);
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 160% */
    }

    &__right {
      &__icon {
        margin-left: 24px;
        rotate: 90deg;
        cursor: pointer;
      }
    }
  }

  &__header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: 12px;

      &__text {
        color: #97a0af;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.035px;

        span {
          color: #000;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.035px;
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 12px;

      &__filter {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        article {
          color: #7043ff;
        }
      }
    }
  }

  &__metric {
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    &__item {
      border-radius: 8px;
      border: 1px solid #dfe1e6;
      background: #fff;
      display: flex;
      padding: 16px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;

      &__label {
        color: #5e6c84;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 12px */
      }

      &__value {
        color: #111;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 150%; /* 36px */
      }
    }
  }

  &__table {
    padding: 0;
    // background-color: white;
    max-width: calc(100vw - 400px);
    overflow-x: scroll;
  }
}
