.tableContainer {
  padding: 0px 20px;
  background-color: #f3f4f6;
  height: 100%;
}

.statusConnected {
  color: green;
}

.statusDisconnected {
  color: red;
}

.moreOptions {
  cursor: pointer;
  color: #1890ff;
}

.platformIcon {
  width: 35px;
  height: 35px;
  margin-right: 12px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  .leftSide {
    display: flex;
    align-items: center;
  }

  .rightSide {
    display: flex;
    align-items: center;
    Button {
      height: 35px;
    }
  }
}
