.addLeadsModal {
  .card {
    border-radius: 12px;
    border: 2px solid #dfe1e6;
    width: 100%;
    height: 220px;

    &.largeCard {
      display: flex;
      align-items: center;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-left: 5px;
    margin-top: 15px;

    h3 {
      font-size: 15px;
      margin: 5px 0px;
    }
    p {
      margin: 0;
      font-size: 12px;
    }
  }

  .smallCardsContainer {
    display: grid;
    grid-template-columns: auto auto;
    gap: 24px;

    .card {
      flex: 1;

      h3 {
        font-size: 15px;
        margin: 5px 0px;
        margin-left: 5px;
      }
      p {
        margin: 0;
        margin-left: 5px;
        font-size: 12px;
      }
      &:not(:last-child) {
        margin-right: 24px;
      }

      &:hover {
        border: 2px solid var(--Linear, #7144ff);
        cursor: pointer;
      }
    }
  }
}

.closeIcon {
  position: absolute;
  right: 30px;
  top: 25px;
  color: #7043ff;
  cursor: pointer;
  &:hover {
    text-shadow: #7043ff 0.4px 0 0.5px;
  }
}

.disabled {
  cursor: not-allowed;
  // filter: grayscale(1);
  pointer-events: none;
}

.comingSoon {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 18px;

  padding: 5px 10px;
  span {
    color: white;
    font-weight: 600;
  }

  &__addFromContact {
    background-color: #fb5919;
  }

  &__addByAI {
    background-color: #7144ff;
  }

  &__addByGroup {
    background-color: #f48fb1;
  }
}

.skip {
  cursor: not-allowed;
  filter: grayscale(1);
  pointer-events: none;
}

.card__image {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}
