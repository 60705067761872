.contactAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dfe1e6;
  background: #fff;

  &__left {
    display: flex;
    align-items: center;

    &__title {
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.07px;

      border-right: 1px solid #ebecf0;
      padding-right: 8px;
    }

    &__dismiss {
      padding-left: 8px;
      overflow: hidden;
      color: var(--Link, #005af4);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.06px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 16px;

    &__icon {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__enrich {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 8px;

      border-left: 1px solid #ebecf0;
      padding-left: 16px;

      &__text {
        color: var(--primary-600-main, #7043ff);
        /* Label/Label large */
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.028px;
        color: #7043ff;
      }
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
