body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #091e42;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

code {
  font-family: 'Roboto';
}

.ant-btn-default:disabled,
.ant-btn-primary:disabled {
  background-color: transparent;
}

.ant-btn-primary:disabled {
  color: white;
  background-color: var(--color-gray-01);
}

.ant-btn-default {
  color: var(--color-blue-03);
  border-color: var(--color-blue-03);
}
