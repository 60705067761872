.search_select {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 15px;
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  border-radius: 6px;
  background-color: #ffffff;

  .prefix_text {
    text-wrap: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001em;
    color: #97a0af;
  }
}
