.adminSettingsCard {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  height: 100%;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    Button {
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;
      border-radius: 8px;
      background-color: #7043ff;
      color: white;
      font-weight: 600;
    }
    Button:hover {
      color: white !important;
      background-color: #835dff;
    }
  }

  .settingsIcon {
    font-size: 18px;
    cursor: pointer;
  }

  .getMoreSeatsButton {
    margin-top: 16px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
