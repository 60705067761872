.container {
  text-align: center;
  display: flex;
  // align-items: center;
  justify-content: center;
  // height: 55vh;
  flex-grow: 1;
}

.centeredContent {
  text-align: center;
}

.container p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 10px;
}

.container section {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.05;
  margin: 0px 0px 20px 0px;
}

.container h5 {
  margin: 30px 0px 5px 0px;
}

.container h1 {
  font-size: 96px;
}

.form-input {
  height: 45px;
}

.codeInputs {
  display: flex;
  justify-content: center;
}

.verificationInput {
  width: 87px;
  height: 87px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 0 5px;
  text-align: center;
}

.resendButton {
  margin: 20px 0px 20px 0px !important;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: black;
  border-radius: 5px;
  text-align: center;
}

.resendButton span {
  cursor: pointer;
}

.cardParent div {
  display: flex;
  position: relative;
}

.card_select {
  border: 1px solid var(--nutrals-100, #051123) !important;
}

.card,
.card_select {
  cursor: pointer;
  padding: 30px;
  width: 335px;
  height: 250px;
  margin: 15px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  border: 1px solid var(--nutrals-600, #dfe1e6);
  background: #fff;

  label {
    position: absolute;
    right: 30px;
    top: 34px;
  }

  .caption {
    color: #000 !important;
    font-family: Roboto !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 28px !important;
    margin: 0;
  }
  .recommended {
    display: inline-flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    margin: 20px 0px;
    border-radius: 6px;
    background: var(--secondary-800, #7feecc);
    color: var(--secondary-200, #074440);
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    letter-spacing: 0.013px;
  }

  .free_account {
    display: inline-flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    margin: 20px 45px;
    border-radius: 6px;
    // background: var(--secondary-800, #7feecc);
    color: var(--secondary-200, #000000);
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    letter-spacing: 0.013px;
  }

  .available {
    color: #000;

    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px; /* 100% */
    letter-spacing: 0.044px;
    margin-bottom: 50px;
  }

  .item_caption {
    color: #000;
    margin: 5px 8px;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.035px;
  }

  .price {
    color: #000;
    /* Title/Title large */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    position: absolute;
    bottom: 50px;
    right: 35px;
  }

  .priceParent {
    position: absolute;
    bottom: 80px;
    right: 34px;
    display: flex;
    .fullPrice {
      color: #000;
      margin-right: 3px;
      font-family: Roboto;
      font-size: 10px;
      font-style: italic;
      font-weight: 500;
      line-height: 10px; /* 100% */
      text-decoration-line: line-through;
    }
    .discount {
      text-decoration-line: line-through;
      color: var(--secondary-400, #1b8374);
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px; /* 100% */
      letter-spacing: 0.04px;
      text-decoration-line: underline;
    }
  }
}

.outreachSuitePopover {
  position: absolute;
  bottom: 65px;
  right: 15px;
  font-size: 10px;
  // width: 13px;
  margin: 0px 2px;
}
