.c-filters {
  // min-height: 72px !important;
  padding: 0 1.5rem;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  // gap: 1rem;
  overflow-x: auto;

  &__primary-btn {
    display: flex;
    justify-content: space-between;

    &-funnel {
      margin: auto;
      margin-right: 10px;
    }
  }

  &__selected-items-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: var(--color-blue-05);
    float: left;
    padding-right: 12px;
    width: 100%;
  }

  &-clearFiltersBtn {
    position: absolute;
    right: 20px;
    top: 8px;
  }

  &-saveFiltersBtn {
    position: absolute;
    right: 120px;
    top: 8px;
  }
}

.prevEl,
.nextEl {
  cursor: pointer;
  background: #f9f0ff;
  border: 1px solid #d3adf7;
  color: #531dab;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  width: 35px;
  height: 35px;
}
