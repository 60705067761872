.customGraph {
  margin-bottom: 24px;
}

.legend {
  .frequencySection {
    color: var(--color-black);
    padding-bottom: 14px;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.03px;
    display: block;
  }
  .item {
    margin-bottom: 18px;
    .bulletBlue {
      //   width: 12px;
      //   height: 12px;
      width: 8px;
      height: 8px;
      background-color: var(--color-blue-03);
      border-radius: 50%;
      display: inline-block;
    }
    .bulletPurple {
      width: 8px;
      height: 8px;
      background-color: var(--color-red-02);
      border-radius: 50%;
      display: inline-block;
      margin-left: 2px;
      margin-right: 2px;
    }
    .bulletYellow {
      width: 8px;
      height: 8px;
      background-color: var(--color-orange-1);
      border-radius: 50%;
      display: inline-block;
      margin-left: 2px;
      margin-right: 2px;
    }
    .bulletLightBlue {
      width: 8px;
      height: 8px;
      background-color: var(--color-blue-09);
      border-radius: 50%;
      display: inline-block;
      margin-left: 2px;
      margin-right: 2px;
    }
    .name {
      color: var(--nutrals-100, var(--color-gray-06));
      padding-left: 12px;
      font-family: 'Roboto';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.014px;
    }

    .frequency {
      color: var(--nutrals-100, var(--color-gray-06));
      text-align: right;
      width: 100%;
      font-family: 'Roboto';
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.055px;
      display: block;
      width: 100%;
    }
  }
}

.intent-popup-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin: 0px;
  }
  span {
    font-size: 12px;
    font-weight: 600;
    color: rgb(177, 177, 177);
  }
}
