.container {
  display: flex;
  flex-direction: column;
  padding: 25px;

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;

    img {
      height: 18px;
      margin-right: 10px;
      filter: brightness(0%);
    }

    span {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .senderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .inputText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    span {
      font-family: Roboto;
      font-weight: 600;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .variables {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-bottom: 25px;

    span {
      font-family: Roboto;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .messageSuggestions {
    display: flex;
    flex-direction: column;
    align-items: left;

    span {
      font-family: Roboto;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }
}

.generateWithAI {
  cursor: pointer;
  position: absolute;
  top: 253px;
  left: 12px;
  display: flex;
  align-items: center;
  z-index: 1000;
  img {
    height: 16px;
    margin-right: 5px;
    filter: brightness(0%);
  }
}

.divider {
  cursor: pointer;
  position: absolute;
  top: 220px;
  display: flex;
  z-index: 1000;
  width: 100%;
}

.compare_A_B_testing {
  color: #da1818 !important;
  line-height: 20px;
  margin-bottom: 20px;
}

.connectContainer {
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100%;

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  .header {
    display: flex;
    align-items: center;
    // padding-bottom: 10px;

    img {
      height: 18px;
      margin-right: 10px;
      filter: brightness(0%);
    }

    span {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .mainBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 24px;
    img {
      height: 42px;
      width: 33.38px;
    }
    p {
      margin: 5px 0px;
      font-size: 16px;
      font-weight: 600;
    }
    span {
      color: #5e6c84;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
