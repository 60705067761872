.tableContainer {
  padding: 20px;
}

.statusConnected {
  color: #02c3ab;
  background: #02c3ab1a;
  border: none;
}

.statusDisconnected {
  color: #ff5c5c;
  background: #ff5c5c1a;
  border: none;
}

.moreOptions {
  cursor: pointer;
  color: #1890ff;
}

.platformIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.header {
  display: flex;
  align-items: center;
}

.tag {
  background: var(--color-blue-04);
  color: #7043ff;
}
