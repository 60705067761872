.rotatingText {
  display: inline-block;
  position: relative;
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-40px);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
}

.fadeOut {
  animation: fadeOut 0.5s ease-out forwards;
}
