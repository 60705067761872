.container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    img {
      height: 18px;
      margin-right: 10px;
      filter: brightness(0%);
    }

    span {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .mainBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 24px;
    img {
      height: 42px;
      width: 33.38px;
    }
    p {
      margin: 5px 0px;
      font-size: 16px;
      font-weight: 600;
    }
    span {
      color: #5e6c84;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
