.EnrichContactModal {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    border-bottom: 1px solid #dfe1e6;
    background: var(--Natural-100, #fff);
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    &__left {
      display: flex;
      align-items: center;
      gap: 16px;

      span {
        color: var(--Nutrals-200, #091e42);
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }

    &__right {
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__body {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    padding: 32px 128px;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--Natural-200, #f9fafb);

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      article {
        &:first-child {
          color: #000;
          text-align: center;

          /* Headline/Headline small */
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }

        &:nth-child(2) {
          color: #97a0af;
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.07px;
          white-space: nowrap;
        }
      }
    }

    &__channel {
      margin-top: 32px;

      &__left {
        background: #fff;
        height: 100%;
        &__text {
          article {
            &:first-child {
              overflow: hidden;
              color: #091e42;
              text-align: justify;
              font-feature-settings: 'salt' on;
              text-overflow: ellipsis;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 8px;
            }
            &:nth-child(2) {
              color: rgba(9, 30, 66, 0.6);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 128.571% */
            }
          }
        }

        &__box {
          margin-top: 24px;
          display: flex;
          gap: 32px;
          flex-wrap: wrap;
        }
      }

      &__right {
        background: #fff;
        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-between;
        height: 100%;

        &__text {
          article {
            &:first-child {
              overflow: hidden;
              color: #091e42;
              text-align: justify;
              font-feature-settings: 'salt' on;
              text-overflow: ellipsis;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 8px;
            }
            &:nth-child(2) {
              color: rgba(9, 30, 66, 0.6);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 128.571% */
            }
          }
        }

        &__point {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          text-align: center;

          article {
            &:nth-child(2) {
              color: var(--Natural-800, #091e42);
              text-align: center;
              font-family: Roboto;
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px; /* 133.333% */
            }
            &:nth-child(3) {
              color: var(--Natural-700, #5e6c84);
              text-align: center;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.07px;
            }
          }
        }

        &__button {
          color: var(--primary-600-main, #7043ff);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.028px;
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid #ebecf0;
    background: var(--Nutrals-1000, #fff);
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    button {
      &:first-child {
        color: var(--primary-600-main, #7043ff);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.028px;
        border: none;
        box-shadow: none;
      }
      &:last-child {
        border-radius: 24px;
        background: #7043ff;
        color: var(--Nutrals-1000, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.028px;
        height: 40px;

        &:hover {
          color: white;
        }
      }
    }
  }
}

.channelBox {
  display: flex;
  width: 333px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  border: 2px solid #dfe1e6;
  background: #fff;

  &__logo {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  &__name {
    overflow: hidden;
    color: #091e42;
    text-align: justify;
    font-feature-settings: 'salt' on;
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  &__count {
    color: rgba(9, 30, 66, 0.6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    span {
      color: var(--Link, #005af4);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      text-decoration-line: underline;
    }
  }

  &__cost {
    color: #97a0af;
    text-align: justify;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
}
