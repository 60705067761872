.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
  flex-grow: 1;
}

.containerText {
  margin: 15px 35px;
  text-align: center;
}

.containerText p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin: 10px;
}

.containerText section {
  font-size: 14px;
}

.animation {
  // padding-top: 20px;
  border-radius: 8px;
}

.animation video {
  max-height: 340px;
  border-radius: 8px;
  width: 440px;
  object-fit: initial !important;
}

.bodyPage {
  display: flex;
  width: 1000px;
  margin: 20px 0px;

  .text {
    margin: 10px 120px;
    // color: #000;
    text-align: center;
    /* Body/Body small */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    letter-spacing: 0.04px;
  }

  .leftPage {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;

    .caption {
      color: var(--nutrals-100, #051123);
      /* Title/Title small */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.014px;
    }
    .copyRegistrationLink {
      position: absolute;
      right: 20px;
    }
    .phone_number {
      width: 228px;
      height: 5px;
      padding: 18px;
      margin-top: 10px;
      align-items: center;
      border-radius: 8px;
      color: black !important;
      border: 1px solid var(--nutrals-500, #97a0af);
      // opacity: 0.33;
      position: relative;
      display: flex;
      justify-content: space-around;
    }

    .code {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #000;

      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.035px;
    }

    .your_code_font {
      font-size: 30px;
    }
    .get_code {
      font-size: 15px;
      cursor: pointer;
      padding: 10px 20px;
      border: 0;
      margin: 15px 0px;
      height: 45px;
      border-radius: 8px !important;
      color: white;
      background-color: #6832eb;
      position: relative;
    }
  }

  .rightPage {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
