@font-face {
  font-family: 'FbSpoilerPasEng';
  src: url('../../common/assets/font/FbSpoilerPasEng-Medium.otf') format('opentype'),
    url('../../common/assets/font/FbSpoilerPasEng-Regular.otf') format('opentype'),
    url('../../common/assets/font/FbSpoilerPasEng-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.holdingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #f9fafb;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    // z-index: 1;
  }

  &::before {
    background-image: url('../../common/assets/svg/login_background_left.svg');
    top: 0;
    left: 0;
    width: calc((100vw - 540px + 10px) / 2);
    height: 100%;
  }

  &::after {
    background-image: url('../../common/assets/svg/login_background_right.svg');
    top: 0;
    right: 0;
    width: calc((100vw - 540px + 10px) / 2);
    height: 100%;
  }

  .logo {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 540px;
    img {
      width: 121px;
      padding-bottom: 40px;
    }
  }

  .footer {
    height: 100%;
    width: 540px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--Natural-600, #97a0af);

    span {
      margin-top: 24px;
      // gap: 20px;
    }
  }

  .form {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    width: 540px;
    height: 616px;
    margin: auto;
    background: white;
    backdrop-filter: blur(30px);
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 70px;
    // box-shadow: 0px 7.293px 21.878px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08), 0 5px 13px 0 rgba(0, 0, 0, 0.1);

    .header {
      margin-bottom: 32px;
      p {
        font-size: 32px;
        font-weight: 700;
        line-height: 37.5px;
        text-align: center;
        margin: 0px 0px 6px 0px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
      }
    }

    h2 {
      color: black;
      font-family: 'FbSpoilerPasEng';
      margin: 30px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    img {
      width: 200px;
      cursor: pointer;
    }

    .googleSSOButton {
      box-sizing: border-box;
      width: 90%;
      height: 50px;
      margin: 0 auto;
      margin-bottom: 20px !important;
      border-radius: 5px;
      display: flex;
      justify-content: left;

      .formIcons {
        width: 20px;
        // margin: auto 0;
        // margin-right: 10px;
        cursor: pointer;
      }

      p {
        font-family: 'FbSpoilerPasEng';
        color: black;
        letter-spacing: 0.002em;
        margin: auto 0;
        word-wrap: break-word;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }
    }

    .btnPlatform:hover {
      color: black;
      background-color: #6832ebe3 !important;
    }

    .btnPlatform {
      box-sizing: border-box;
      width: 90%;
      height: 50px;
      margin: 0 auto;
      margin-bottom: 20px !important;
      border-radius: 5px;
      display: flex;
      justify-content: left;

      .formIcons {
        width: 20px;
        margin: auto 0;
        margin-right: 10px;
      }

      p {
        font-family: 'FbSpoilerPasEng';
        letter-spacing: 0.002em;
        // color: var(--color-white);
        margin: auto 0;
        word-wrap: break-word;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
      }
    }

    form {
      p {
        // color: var(--color-white);
        font-family: 'FbSpoilerPasEng';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      .formRow {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 32px;
        border-radius: 5px;
        text-align: left;
        position: relative;

        .password {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .type {
          color: var(--Natural-800, #091e42);
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.07px;
        }

        .formLabel {
          font-family: 'FbSpoilerPasEng';
          font-style: normal;

          font-size: 16px;
          line-height: 12px;
          letter-spacing: 0.002em;
          // color: var(--color-blue-05);
          float: left;
          padding-bottom: 10px;
          width: 100%;

          font-weight: 500;
          margin-left: 5px;
        }

        input {
          float: left;
          width: 100%;
          box-sizing: border-box;
          height: 50px;
          // background: var(--color-transparent);
          // color: var(--color-white);
          border-radius: 5px;
          border: 1px solid var(--color-white);
          margin: 0 auto;
        }

        span {
          input {
            height: 38px;
          }
          input:hover {
          }
        }
      }

      .error {
        color: var(--color-orange);
        margin-bottom: 2px;
      }

      .btnLogin {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        // background: var(--color-blue-03);
        border-radius: 24px;
        margin: 0 auto;
        font-family: 'FbSpoilerPasEng';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.002em;
        // color: var(--color-white);
      }

      .btnLogin:hover {
        // color: var(--color-white);
        background-color: #6832ebe3 !important;
      }
      .forgotPasswordButton {
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.07px;
        color: var(--Link, #005af4);
        cursor: pointer;
      }
    }
  }
}

.passIcon {
  position: absolute;
  right: 10px;
  bottom: 16px;
  font-size: 1.5rem;
  color: black;
  cursor: pointer;
}

.Divider {
  border-color: #dfe1e6 !important;
  color: #dfe1e6 !important;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;

  .Text {
    color: var(--Natural-600, #97a0af);
    text-align: justify;
    font-feature-settings: 'salt' on;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}
