.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // flex-grow: 1;
  background: radial-gradient(rgba(200, 188, 255, 0.4) 2px, transparent 0);
  background-size: 25px 25px;
  position: relative;
  overflow-y: auto;
}
