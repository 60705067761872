.expandContainer {
  background: var(--color-white);
  border: 1px solid var(--color-white-04);
  border-radius: 8px;
  width: 100%;
  margin-top: 14px;

  .expandBodyItem {
    width: 100%;
    margin: 12px 8px 12px 8px;
    border-bottom: 1px solid var(--color-gray-03);
    width: auto;
    padding-bottom: 16px;
    .label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: var(--color-blue-05);
      padding-left: 0px;
    }
    .reset {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: var(--color-gray-01);
      cursor: pointer;
    }

    .selectBox {
      margin-top: 12px;
      width: 100%;
    }
  }
}

.itemLabel {
  padding-right: 8px;
}
