.modal {
  display: flex;
}

.ant-modal .ant-modal-content {
  min-width: fit-content !important;
}

.ant-modal-content {
  min-width: fit-content !important;
}
