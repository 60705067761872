.section {
  padding-top: 16px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-04);

  .nameSection {
    padding: 0 16px 0 16px;
    font-weight: 600;
    font-size: 16px;
  }

  .body {
    padding: 16px 32px 32px 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .selectCustomized {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        color: var(--color-blue-05);
        font-weight: 500;
      }
    }
    .information {
      display: flex;
      justify-content: space-between;

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
