.headerStyle {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0px;
  border-bottom: 1px solid #ebecf0;

  img {
    width: 20px;
    margin-right: 10px;
  }

  h1 {
    font-size: 14px;
    font-weight: bold;
    color: #7043ff;
  }
}

.headerLoadingBoxText {
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 300px;
}

.contentStyle {
  min-height: 120;
  background-color: white;
  padding: 30px 0px 10px 0px;

  Input {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.001em;
    text-align: left;
    height: 40px;
  }

  .formSelect {
    display: inline-block;
    width: calc(50% - 10px);
    margin: 0px 20px 0px 0px;

    p {
      margin-bottom: 5px;
      margin-top: 0px;
      font-weight: 600;
    }

    span {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.001em;
      text-align: left;
    }
  }

  .enrichment {
    background-color: #f6f6f6;
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 35px;
    font-family: Roboto;
    line-height: 16px;
    letter-spacing: 0.004em;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .left {
        p {
          font-size: 16px;
          font-weight: bold;
          margin: 5px 0px;
        }

        span {
          font-size: 12px;
          color: #666;
        }
      }

      .right {
        display: flex;
        align-items: center;
        width: 80px;

        flex-direction: row;
        justify-content: space-around;

        span {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }

    .settings {
      display: flex;
      border-top: 1px solid #ebecf0;
      padding: 15px 0px 0px 0px;
      flex-direction: row;
      justify-content: space-between;

      .options {
        display: flex;
        align-items: flex-start;
      }

      img {
        padding-right: 2px;
      }

      span {
        min-width: fit-content;
        font-weight: 600;
        font-size: 12px;
      }
    }
  }
}

.footerStyle {
  padding: 16px 16px 0px 16px;
  background-color: white;
  border-top: 1px solid #ebecf0;

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .button {
      background-color: #7043ff;
      color: white;
      border-radius: 8px;
    }

    .button:disabled {
      background-color: lightgray;
    }

    .centeredText {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: #5e6c84;

      img {
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .back {
      cursor: pointer;
      font-family: Roboto;
      color: black;
      font-size: 14px;
      font-weight: 800;

      img {
        margin-right: 5px;
      }
    }
  }
}

// .headerStyle {
//   display: flex;
//   align-items: center;
//   background-color: white;
//   padding: 0px;
//   border-bottom: 1px solid #ebecf0;

//   img {
//     width: 20px;
//     margin-right: 10px;
//   }

//   h1 {
//     font-size: 14px;
//     font-weight: bold;
//     color: #7043ff;
//   }
// }

// .contentStyle {
//   min-height: 120;
//   background-color: white;
//   padding: 30px 0px;

//   Input {
//     font-family: Roboto;
//     font-size: 14px;
//     font-weight: 600;
//     line-height: 20px;
//     letter-spacing: 0.001em;
//     text-align: left;
//     height: 40px;
//   }

//   .formSelect {
//     display: inline-block;
//     width: calc(50% - 10px);
//     margin: 0px 20px 0px 0px;

//     p {
//       margin-bottom: 5px;
//       margin-top: 0px;
//       font-weight: 600;
//     }

//     span {
//       font-family: Roboto;
//       font-size: 14px;
//       font-weight: 600;
//       line-height: 20px;
//       letter-spacing: 0.001em;
//       text-align: left;
//     }
//   }

//   .enrichment {
//     background-color: #f6f6f6;
//     border-radius: 8px;
//     padding: 15px 20px;
//     margin-top: 20px;
//     font-family: Roboto;
//     line-height: 16px;
//     letter-spacing: 0.004em;

//     .top {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       padding-bottom: 10px;

//       .left {
//         p {
//           font-size: 16px;
//           font-weight: bold;
//           margin: 5px 0px;
//         }

//         span {
//           font-size: 12px;
//           color: #666;
//         }
//       }

//       .right {
//         display: flex;
//         align-items: center;
//         width: 80px;

//         flex-direction: row;
//         justify-content: space-around;
//         span {
//           font-size: 12px;
//           font-weight: bold;
//         }
//       }
//     }

//     .settings {
//       display: flex;
//       border-top: 1px solid #ebecf0;
//       padding: 15px 0px 0px 0px;
//       flex-direction: row;
//       justify-content: space-between;
//       .options {
//         display: flex;
//       }
//       img {
//         padding-right: 2px;
//       }
//       span {
//         font-weight: 600;
//         font-size: 12px;
//       }
//     }
//   }
// }

// .footerStyle {
//   padding: 16px 16px 0px 16px;
//   background-color: white;
//   border-top: 1px solid #ebecf0;

//   .section {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     text-align: center;

//     .button {
//       background-color: #7043ff;
//       color: white;
//       border-radius: 8px;
//     }

//     .centeredText {
//       display: flex;
//       margin-left: 25px;
//       font-size: 12px;
//       font-weight: 600;
//       color: #5e6c84;
//       min-width: 300px;
//       width: 300px;

//       img {
//         cursor: pointer;
//         margin-left: 5px;
//       }
//     }

//     .back {
//       cursor: pointer;
//       font-family: Roboto;
//       color: black;
//       font-size: 14px;
//       font-weight: 800;

//       img {
//         margin-right: 5px;
//       }
//     }
//   }
// }
