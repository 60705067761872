.sideBar {
  display: flex;
  width: 330px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-right: 1px solid #dfe1e6;
  background: var(--Natural-100, #fff);

  &__title {
    color: var(--Nutrals-200, #091e42);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }

  &__list__title {
    color: var(--Natural-600, #97a0af);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    margin: 16px 0;
  }

  &__list__item {
    overflow: hidden;
    color: var(--Natural-800, #091e42);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    cursor: pointer;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    border: none;
    justify-content: space-between;
    margin-bottom: 0px;
    border-radius: 10px;
    margin-bottom: 5px;

    &:hover {
      outline: 1px solid var(--Linear, #7144ff);
    }

    &__premium {
      &:hover {
        border: none;
        cursor: not-allowed;
      }
    }
  }

  &__list__custom {
    width: 100%;
    max-height: 300px;
    overflow: auto;
    padding: 1px 5px 1px 1px;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
  }

  &__list__item--active {
    border-radius: 10px;
    outline: 1px solid var(--Linear, #7144ff);
    background: #faf9ff;
    width: 100%;
  }

  &__createList {
    color: var(--primary-600-main, #7043ff);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.028px;
    cursor: pointer;
  }
}

.premium_badge {
  height: 20px;
  width: 70px;
}

.moreOptions {
  width: 20px;
  height: 20px;
}

.removePadding {
  padding: 0;

  &:hover {
    background: #faf9ff;
  }
}
