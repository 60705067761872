.socialContacts {
  text-align: left;
  margin-top: -20px;
  img {
    margin-right: 16px;
  }
}

.details {
  display: flex !important;

  span {
    min-width: 25px;
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.005em;
    color: var(--color-blue-05);
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 5px 0px 0px;
  }

  .email_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    float: left;
  }
}

.companyField {
  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: var(--color-gray-02);
  }
}

.contacts_same_company_parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  cursor: pointer;
  .caption_parent {
    display: flex;
    text-align: start !important;
    flex-direction: column;
    align-items: flex-start;
    line-height: 15px;
    margin: 0px 0px 0px 10px;

    .header {
      font-size: 13px;
      font-weight: 600;
    }

    .body {
      font-size: 12px;
    }
  }
  img {
    border-radius: 20px;
    width: 30px;
    height: 30px;
  }
}

.top {
  margin-top: 16px;
}

.right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.title_msg {
  overflow: hidden;
  color: var(--tertiary-100, #33032c);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
}

.msg_body {
  overflow: hidden;
  color: var(--tertiary-100, #33032c);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.imgParentEmailAddress,
.imgParentPhone {
  border-radius: 22.043px;
  background: var(--primary-600-main, #7043ff);
  display: flex;
  width: 25px;
  height: 25px;
  padding: 4.409px;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 4.409px;
}

.imgParentTelegram {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 4.409px;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  gap: 4.409px;
  border-radius: 22.043px;
  background: #4fa7d7;
}

.parentTelegramCaption,
.parentPhoneCaption,
.parentEmailCaption {
  overflow: hidden;
  color: var(--primary-100, #170138) !important;
  text-overflow: ellipsis;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: 0.014px !important;
}

.companyName {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: var(--color-blue-03);
}

.vertical {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: var(--color-blue-05);
}

.titleSection {
  color: var(--nutrals-100, #051123);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.024px;
  margin-top: 0px;
}

.communities {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
  .item {
    display: flex;
    border-radius: 8px;
    border: 1px solid var(--primary-600-main, #7043ff);
    background: #fff;
    max-height: 36px;
    span {
      color: var(--primary-600-main, var(--color-blue-03));
      text-align: center;
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.06px;
      display: flex;
      height: 36px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
    img {
      margin-left: 8px;
    }
  }
  .contactTypesNoBorderParent {
    .contactTypesNoBorder {
      border-radius: 8px;
      border: 1px solid var(--primary-600-main, #7043ff);
      background: var(--primary-600-main, #7043ff);
      display: flex;
      span {
        text-align: center;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.06px;
        display: flex;
        height: 36px;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--primary-600-main, #7043ff);
        color: #fff;
      }
      img {
        margin-left: 8px;
      }
    }
  }
}

.bioDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: var(--color-gray-06);
}

.contactDetails {
  background: white;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0px;
  .rowData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border-radius: 15px;
    }

    .parent_company {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.sendMessageContainer {
  max-height: fit-content;
  /* padding: 30px; */
  /* border-radius: 8px; */
  /* border: 1px solid #d9d9d9; */
}
.select {
  width: 100%;
  height: 40px;

  div {
    height: 40px !important;
    align-items: unset !important;
  }
}

.sendMessage {
  position: relative;
  border-radius: 8px;

  .buttons {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .inputText {
    border: none;
    outline: none;
    border-radius: 8px;
    border: 1px solid #d9d9d9 !important;
    // margin: 20px 0px;
    height: 110px !important;
  }

  span {
    cursor: pointer;
    margin: 5px;
    color: #7043ff;
  }
}

.sentMessages {
  width: 100%;

  .messageContainer {
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 16px;
    background-color: #f9fafb;
  }

  p {
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0025em;
    white-space: pre-line;
  }

  .metaInfo {
    display: flex;
    justify-content: space-between;
    color: #5e6c84;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;

    span {
      text-align: left;
    }

    span:last-child {
      text-align: right;
    }
  }
}

.fixedOutreach {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  background-color: #f4f5f7;
  max-height: 30%;
}
