.header {
  display: flex;
  padding-left: 20px;
  background-color: white;
}

.tabs {
  display: flex;
  align-items: center;
  span {
    font-weight: 600;
    font-size: 15px;
  }
  img {
    height: 18px;
    margin-right: 5px;
  }
}
