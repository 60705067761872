.rightSide {
  text-align: end;
  Button {
    background-color: #7043ff;
    color: white;
    border-radius: 8px;
    height: 40px;
  }
  Button:hover {
    background-color: #8964ff;
    color: white !important;
  }
}

.leftSide {
  text-align: start;
  Button {
    background-color: #7043ff;
    color: white;
    border-radius: 8px;
    height: 40px;
  }
  Button:hover {
    background-color: #8964ff;
    color: white !important;
  }
}

.continue {
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
    height: 10px;
  }
}

.launch {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    height: 15px;
  }
}

.pauseCampaign {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-weight: 500;
  }
  .switchPause {
    justify-content: center;
    span {
      padding-right: 5px;
    }
  }
}
