.container {
  display: flex;
  flex-direction: column;
  //   padding: 20px 0px;
  //   border-top: 1px solid red;
  //   width: 300px;

  p {
    font-weight: 700;
    color: gray;
    margin: 5px 0px;
  }
  .list {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;

    .leftSide {
      display: flex;
      align-items: center;
      flex-grow: 1;
      p {
        color: black;
        font-weight: 600;
        margin: 15px 0px;
        margin-left: 10px;
      }
    }
  }

  .points_used {
    color: var(--Nutrals-100, #051123);
    /* Title/Title medium */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;
  }

  .points_used_body {
    color: var(--Nutrals-300, #5e6c84);
    /* Body/Body small */
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    letter-spacing: 0.04px;
  }

  .Button {
    margin-top: 10px;
    border-radius: 8px;
    height: 45px;
    color: white;
    background-color: #7043ff;
  }
  .Button:hover {
    color: white !important;
    background-color: #7f58ff;
  }

  .switchItem {
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }

  .switchLabel {
    margin-left: 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 600;
    // margin-top: 10px;
  }
}

.loading {
  display: flex;
  align-items: center;

  min-height: 534px;
}

.loading__list {
  min-height: 300px;
  display: flex;
  align-items: center;
}
.columns {
  position: absolute;
  left: 0;
  right: 0 !important;
  width: 400px;
  // margin: 0px 50px;
  top: 50px;
  .insideColumns {
    color: #acacac;
    padding: 0px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
  }
}
