.modal {
  display: flex;
}

.text {
  text-align: center;
}

.button {
  width: 20%;
  border-radius: 8px !important;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
