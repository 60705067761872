.c-side-filter {
  height: 100%;
  width: 265px;
  border-right: 1px solid var(--color-white-04);
  background-color: var(--color-white);
  box-sizing: border-box;

  animation: slideIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  z-index: 0;

  &__items {
    overflow: auto;

    &__header {
      height: 75px;
      display: flex;

      justify-content: space-between;
      align-items: center;

      padding: 0 16px;

      overflow: hidden;

      &-text {
        font-weight: 700;
        font-size: 14px;
        color: var(--color-gray-01);
      }

      &-close {
        cursor: pointer;
      }
    }
  }


  &__button {
    margin: 20px auto 20px auto;
  }
}

.filters-sidebar {
  display: flex;
}

@keyframes slideIn {
  from {
    width: 0;
  }

  to {
    width: 240px;
  }
}