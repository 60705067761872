.save-filters-container {
  width: 250px;
  margin: 10px;
}

.save-filters-button {
  margin: 10px 0;
  width: 100%;
}

.selected-item {
  background: var(--primary-960, var(--color-blue-04));
  border: 1px solid var(--primary-800, var(--color-blue-08));
  border-block-end: 1px solid var(--primary-800, var(--color-blue-08)) !important;
}

.save-filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
}

.save-filters-title {
  display: flex;
  align-items: center;
}

.save-filters-icon {
  margin-right: 8px;
  color: #8c8c8c;
}

.saved-filters-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 0;
}

.reset-filters {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    background: unset !important;
  }
}

.saved-filter-item {
  padding: 6px 10px !important;
  background-color: #fff;
  border-radius: 8px;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: var(--primary-960, var(--color-blue-04));
    border: 1px solid var(--primary-800, var(--color-blue-08));
    border-block-end: 1px solid var(--primary-800, var(--color-blue-08)) !important;
  }
}

.saved-filter-icon {
  margin-right: 8px;
  color: #8c8c8c;
}

.modal-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}