.selectPlanModal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .currentPlan,
  .selectPlan,
  .billingCycle {
    margin-bottom: 24px;
    text-align: center;
  }

  .planButton,
  .cycleButton {
    margin: 8px;
    font-size: 16px;
    padding: 12px 24px;
    border-radius: 8px;

    &.ant-radio-button-wrapper-checked {
      background-color: #7043ff;
      color: #fff;
    }
  }

  .ant-modal-footer {
    border-top: none;
    padding-top: 0;

    .ant-btn {
      margin-right: 8px;
    }
  }
}
