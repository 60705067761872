.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-radius: 8px;
  width: 100%;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

input {
  width: 100%;
}

.tags {
  color: #7043ff !important;
  background-color: #f2f0ff !important;
}

.redTags {
  color: #ff5c5c !important;
  background-color: #ff5c5c1a !important;
  border-color: #f24e4e1a;
}
