.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  position: relative;
  margin-right: 5px;
  span {
    margin: 0px !important;
  }
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.colorF56a00 {
  background-color: #f56a00;
}

.color7265e6 {
  background-color: #7265e6;
}

.colorFfbf00 {
  background-color: #ffbf00;
}

.color00a2ae {
  background-color: #00a2ae;
}

.colorF44336 {
  background-color: #f44336;
}

.color009688 {
  background-color: #009688;
}
