.header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  p {
    font-size: 22px;
    margin: 0px;
    font-weight: 600;
  }
  img {
    padding-left: 5px;
  }
}

.modalContent {
  display: flex;
  justify-content: space-between;
  min-height: 620px;
}

.leftPanel {
  width: 50%;
  // padding: 20px;
  max-height: 595px;
  overflow-y: scroll;
  padding: 20px 20px 0px 20px;
  // border-right: 1px solid #e8e8e8;
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 50%;
  padding: 16px;
  // border-left: 1px solid #e8e8e8;
}

.previewTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.previewImage {
  // box-shadow: 0 0 10px 4px rgb(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0px 20px;
  width: 100%;
  display: flex;
  // align-items: center;
  justify-content: center;
  // width: 100%;
  height: 100%;
  background-color: #f5f5f5 !important;
  background: radial-gradient(rgba(200, 188, 255, 0.4) 2px, transparent 0);
  background-size: 30px 30px;
  // position: relative;
  // overflow-y: auto;
  img {
    width: 100%;
    // height: 50%;
    margin: 0px 0px 0px 0px;
  }
  //   max-height: 400px;
  // object-fit: cover;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  align-items: center;

  Button {
    border-radius: 8px;
    height: 45px;
    color: white;
    // width: 100%;
    background-color: #7043ff;
    display: flex;
    align-items: center;
    img {
      height: 15px;
      width: 15px;
      margin-right: 0px;
      margin-left: 5px;
    }
  }
  Button:hover {
    color: white !important;
    background-color: #7f58ff;
  }
}
