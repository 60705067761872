.contentStyle {
  min-height: 120;
  background-color: white;
  padding: 0px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    img {
      height: 40px;
      width: 40px;
      margin-top: 40px;
    }

    p {
      margin: 10px 0px 8px 0px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }

    span {
      font-size: 14px;
      margin-bottom: 20px;
    }

    Button {
      border-radius: 8px;
      width: 200px;
      text-align: center;
      margin: 0px 5px;
    }
    .footer {
      display: flex;
      margin-bottom: 30px;
    }
  }
}
