.profileCard {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  // margin-bottom: 16px;
  overflow: auto;

  height: 100%;

  .profileInputsCard {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;

    .inputRow {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .inputContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .inputTitle {
          font-size: 14px;
          color: #777;
        }
      }
    }
  }
}

.progressBarContainer {
  margin-top: 16px;
  // display: flex;
  // flex-direction: column;
}

.progressText {
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
}

.yourPlan {
  margin-bottom: 60px;

  p {
    margin: 0;
    line-height: normal;
    font-weight: 600;
  }
}

.buyMoreBar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .buyMore {
    color: #7043ff;
    font-weight: 600;
    cursor: pointer;
  }
}

.pointsTable {
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px 0px 8px 8px;
    background-color: #f9f9f9;
    margin-top: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    th,
    td {
      text-align: center;
      padding: 2px 13px;
    }

    th {
      background-color: #7043ff;
      color: #ffffff;
    }

    tr:hover {
      background-color: #f1f1f1;
    }

    td {
      color: #333;
    }
  }
}
