.contentStyle {
  min-height: 120;
  background-color: white;
  padding-top: 20px;
  border-top: 1px solid #ebecf0;

  // padding: 0px 0px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  Input {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.001em;
    text-align: left;
    height: 40px;
  }

  .formSelect {
    display: inline-block;
    width: calc(50% - 10px);
    margin: 0px 20px 0px 0px;

    p {
      margin-bottom: 5px;
      margin-top: 0px;
      font-weight: 600;
    }

    span {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.001em;
      text-align: left;
    }
  }
  // Button {
  //   width: 100%;
  //   // align-items: center;
  // }
}

.listsContainer {
  p {
    font-weight: 600;
  }
}

.list {
  // display: flex;
  // flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  padding: 20px 0px;
  border-top: 1px solid #ebecf0;
  .checkbox {
    display: flex;
    align-items: center;

    p {
      color: black;
      font-weight: 600;
      margin: 0 10px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid #ebecf0;
  padding-top: 20px;
  Button {
    height: 40px;
    width: 200px;
    border-radius: 8px;
  }
}
