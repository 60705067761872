.footer_center {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modalButton {
  min-width: 250px;
  margin-bottom: 10px;
  height: 45px;
  border-radius: 8px !important;
  color: white;
  background-color: #6832eb;
  position: relative;
}

.modaBackButton {
  min-width: 150px;
  margin-bottom: 10px;
  height: 45px;
  border-radius: 8px !important;
  position: relative;
  color: var(--primary-600-main, #7043ff);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.014px;
}

.modalButton:hover {
  color: white !important;
  background-color: #3e1192;
}

.modalButton:disabled {
  cursor: default;
  color: #d9d9d9 !important;
  // background-color: white;
  // border: #d9d9d9 1px bold;
  border-radius: 8px;
  opacity: 0.3;
  background: var(--primary-600-main, #7043ff);
}

.terms {
  display: flex;
  margin: 30px;
  justify-content: center;
  margin-top: 200px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.outreachSupported {
  color: var(--nutrals-100, #051123);
  /* Label/Label medium */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
  margin: 0px 22px 20px 22px;
  position: absolute;
  top: 210px;
  left: 219px;

  .dedicated {
    color: var(--primary-600-main, #7043ff);
    /* Label/Label medium */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.06px;
  }
}

.outreachSupportedPageC {
  color: var(--nutrals-100, #051123);
  /* Label/Label medium */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
  margin: 0px 22px 20px 22px;
  position: absolute;
  right: 0px;
  bottom: 140px;
  .dedicated {
    color: var(--primary-600-main, #7043ff);
    /* Label/Label medium */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.06px;
  }
}
