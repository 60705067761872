.footer {
  position: absolute;
  display: flex;
  bottom: 0;
  width: 100%;
  right: 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modalButton {
  width: 85%;
  margin: 40px 0px;
  height: 45px;
  border-radius: 8px !important;
  color: white;
  background-color: #6832eb;
  position: relative;
}

.modalButton_page_f {
  width: 85%;
  margin: 20px 0px;
  height: 45px;
  border-radius: 8px !important;
  color: white;
  background-color: #6832eb;
  position: relative;
}

.modaBackButton {
  min-width: 150px;
  width: 85%;
  margin-bottom: 20px;
  height: 45px;
  border-radius: 8px !important;
  position: relative;
  color: var(--primary-600-main, #7043ff);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.014px;
}

.modalButton_page_f:hover,
.modalButton:hover {
  color: white !important;
  background-color: #3e1192;
}

.modalButton:disabled {
  cursor: default;
  color: #d9d9d9 !important;
  background-color: white;
  border: #d9d9d9 1px bold;
}

.terms {
  position: absolute;
  bottom: 0;
  margin: 0px 0px 15px 0px;
  padding: 15px 0px;
}

.container {
  height: 100%;
}
