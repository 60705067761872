.selectPlanType {
  display: flex;
  justify-content: center;
  // margin-bottom: 10px;
}

.card {
}

.plan {
  display: flex;
  flex-direction: column;
  p {
    // font-weight: 600;
    margin: 0px;
    // font-size: 20px;
    // margin-bottom: 6px;
    font-size: 20px;
    font-weight: 500;
    line-height: 40px;
    display: block;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  ul {
    padding-inline-start: 0px;
    margin: 0px;
    span {
      font-weight: 600;
    }
  }
}
.allInPrev {
  color: #7043ff !important;
  display: flex;
  align-items: flex-start !important;
}
.features {
  //   overflow-y: auto;
  //   max-height: 160px;
  padding-top: 10px;
  span {
    font-weight: 600;
  }
}

.selectSeats {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  position: absolute;
  // width: 248px;
  // margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  p {
    margin: 0px;
  }
}

.collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.displayPopular {
  // margin-top: 10px;
  margin-left: 40px;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  span {
    color: white;
    font-weight: 500;
    font-size: 13px;
    background: linear-gradient(88deg, #6a45f6 0%, #b84bf8 100.16%) !important;
    border: 0px solid white;
    border-radius: 0px 8px 0px 10px;
    padding: 13px;
    width: 110px;
    height: 35px;
    display: flex;
    align-items: center;
  }
}

.seeMore {
  padding-top: 20px;
  position: absolute;
  bottom: 20px;
}

.upgradeButton {
  display: flex;
  justify-content: center;
  padding: 10px;
  Button {
    margin: 0 !important;
  }
}

.trialDays {
  display: flex;
  justify-content: center;
  color: red;
  margin-top: 5px;
}
.title {
  padding: 20px 10px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  font-size: 25px;
  font-weight: 800;
  width: 24.5%;
  margin-left: 5px;
}

.fields {
  font-size: 23px;
  font-weight: 700;
  color: #7043ff;
  padding: 30px 0px 10px 10px;
  display: flex;
  align-content: center;
  width: 110px;
  justify-content: space-between;
}

.content {
  font-size: 18px;
  font-weight: 700;
  padding: 30px 10px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  margin-left: 5px;
  margin-bottom: 5px;
  width: 24.5%;
}

.quantity {
  display: flex;
  // flex-direction: column;
  position: absolute;
  bottom: 53px;
  left: 0;
  right: 0;
  justify-content: center;
  // padding-top: 25px;
  align-items: center;
  font-weight: 500;
}
.quantityWarmed {
  display: flex;
  // flex-direction: column;
  position: absolute;
  bottom: 80px;
  left: 0;
  right: 0;
  justify-content: center;
  // padding-top: 25px;
  align-items: center;
  font-weight: 500;
}

.outreachSuite {
  display: flex;
  // position: relative;
  // top: 0.5px;
  // left: 63px;
  align-items: center;
  font-size: 10px;
}

.outreachSuiteInfoIcon {
  display: flex;
  // position: absolute;
  position: relative;
  top: 0.2px;

  // bottom: 74px;
  // left: 48px;
  // align-items: center;
  font-size: 10px;
  width: 11px;
  margin: 0px 2px;
}
.outreachSuitePopover {
  display: flex;
  position: absolute;
  bottom: 68px;
  left: 65px;
  font-size: 10px;
}

.seatsIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  // background-color: #7043ff;
  background: linear-gradient(88deg, #6a45f6 0%, #b84bf8 100.16%) !important;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
