.card {
  border: 1px solid #dfe1e6;
  flex: 1 1 0;
  max-width: 290px;
  h3 {
    margin-top: 0px;
    margin-bottom: 22px;
    color: var(--Natural-800, #091e42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.028px;
  }
}

.leadsOverview {
  width: 100%;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
}

.progressBar {
  display: flex;
  height: 10px;
  margin-bottom: 20px;
}

.positive {
  background-color: #02c3ab;
  border-radius: 0px 12px 12px 0px;
  position: relative;
}

.neutral {
  background-color: #f9c628;
  border-radius: 0px 0px 0px 0px;
  position: relative;
}

.negative {
  background-color: #ff5c5c;
  border-radius: 12px 0px 0px 12px;
  position: relative;
}

.nothing {
  background-color: #dfe1e6;
  border-radius: 12px 0px 0px 12px;
  position: relative;
}

.loadingBar {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    #02c3ab,
    #f9c628,
    #ff5c5c,
    #02c3ab,
    #f9c628,
    #ff5c5c,
    // #02c3ab,
    // #f9c628,
    // #ff5c5c,
    // #02c3ab,
    // #f9c628,
    // #ff5c5c
  );
  animation: loadingAnimation 4s linear infinite;
}

@keyframes loadingAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100px 0;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .text {
    .header {
      color: var(--Natural-600, #97a0af);
      font-feature-settings: 'salt' on;
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}

.count {
  color: var(--Black-700---Secondary, var(--Color-Black-700, #4d4d4d));
  font-feature-settings: 'salt' on;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

// @media (max-width: 1700px) {
.hide {
  display: none !important;
}
// }
