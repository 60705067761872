.paymentSummaryModal {
  .ant-modal-footer {
    display: none;
  }
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
}

.summaryList {
  .listItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }
  }

  .summaryItem,
  .summaryTotal {
    span {
      font-size: 16px;
      &:first-child {
        color: #555;
        font-weight: normal;
      }
      &:last-child {
        color: #000;
        font-weight: bold;
      }
    }
  }

  .planName {
    color: #007bff;
    font-weight: bold;
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.cardInfoSection,
.poweredByStripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.cardIcon {
  font-size: 20px;
  margin-right: 8px;
}

.cardText {
  font-size: 16px;
}

.actionButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}

.reviewButton,
.payButton {
  width: 200px;
  border-radius: 8px;
  font-weight: bold;
}

.payButton {
  background-color: #7043ff;
  border-color: #7043ff;
  &:hover {
    background-color: #7d56ff;
    border-color: #7043ff;
  }
}

.poweredByStripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  .poweredBy {
    margin-right: -3px;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }

  .stripeLogo {
    height: 24px;
  }
}

.confirmationSection {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 16px;

  .confirmationIcon {
    color: #7043ff;
    font-size: 22px;
    margin-right: 10px;
  }

  .confirmationText {
    font-size: 14px;
    color: #666;
  }
}

.loading {
  background-color: white;
  // min-height: 380px;
  margin-top: 150px;
  margin-bottom: 190px;
  display: flex;
  align-items: center;
}

.completed {
  background-color: white;
  // min-height: 380px;
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin: 0;
    font-weight: 700;
  }
}
