.loading {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 50%;
  }
  
  .loading__dot {
    animation: dot ease-in-out 1s infinite;
    background-color: grey;
    display: inline-block;
    height: 10px;
    margin: 3px;
    width: 10px;
    border-radius: 10px;
  }
  
  .loading__dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  
  .loading__dot:nth-of-type(3) {
    animation-delay: 0.3s;
  }
  
  @keyframes dot {
    0% { background-color: #7043ffa3; transform: scale(0.5); }
    50% { background-color: #7043FF; transform: scale(1); }
    100% { background-color: #7043ffa3; transform: scale(0.5); }
  }