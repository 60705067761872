.container {
  display: flex;
  span {
    color: #7043ff;
    padding: 2px 8px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.009em !important;
  }

  .flex_center {
    display: flex;
    align-items: center;
    border-color: rgb(233, 230, 230);
    color: #7043ff;
    border-radius: 10px;
  }

  img {
    // margin-bottom: 2px;
    margin: 0px 2px;
  }
}

.dropDown {
  cursor: pointer;
}

.spin {
  position: absolute;
  right: -200px;
  font-size: 11px;
  display: flex;
  width: 95px;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5px;
}
