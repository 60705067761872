.upgradePlanModal {
  .ant-modal-footer {
    display: none;
  }
  .userRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }

    .ant-checkbox-wrapper {
      flex-grow: 1;

      .ant-checkbox + span {
        margin-left: 8px;
        font-size: 16px;
      }
    }
  }
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.planSelection {
  margin-bottom: 20px;
}

.planDetails {
  margin-bottom: 20px;

  p {
    font-size: 16px;
    margin-bottom: 10px;
    strong {
      color: #007bff;
    }
  }
}

.cardInfoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .cardIcon {
    font-size: 20px;
    margin-right: 8px;
  }

  .cardText {
    font-size: 16px;
  }
}

.poweredByStripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .poweredBy {
    margin-right: -5px;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }

  .stripeLogo {
    height: 24px;
  }
}

.actionButtons {
  display: flex;
  justify-content: space-between;

  .cancelButton,
  .upgradeButton {
    width: calc(50% - 10px);
    border-radius: 8px;
    font-weight: bold;
  }

  .upgradeButton {
    background-color: #7043ff;
    border-color: #7043ff;
    &:hover {
      background-color: #7d56ff;
      border-color: #7043ff;
    }
    &:disabled {
      color: #ccc;
      background-color: white;
      border: 1px solid #ccc;
    }
  }
}

.billingCycleSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .ant-radio-group {
    display: flex;
    justify-content: center;
  }
  .ant-radio-button-wrapper {
    margin: 0 8px;
  }
}

.note {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.loading {
  background-color: white;
  // min-height: 380px;
  margin-top: 150px;
  margin-bottom: 173px;
  display: flex;
  align-items: center;
}

.completed {
  background-color: white;
  // min-height: 380px;
  margin-top: 0px !important;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 329px;
  h2 {
    margin: 0;
    font-weight: 700;
  }
}

.allUsers {
  max-height: 300px;
  overflow-y: auto;
}
