.paywall-modal {
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 408px;
    background-color: var(--color-white);
    padding: 1rem;

    h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.005em;
        color: #5E6C84;
        margin: 0;

    }

    span {
        font-weight: 600;
    }
}