.title {
  padding: 20px 0px;
  font-size: 20px;
  font-weight: 700;
  width: 10%;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.fields {
  font-size: 23px;
  font-weight: 700;
  color: #7043ff;
  padding: 20px 0px 10px 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.contentTopic {
  font-size: 18px;
  font-weight: 700;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  min-width: 30%;
}
.contentData {
  font-size: 18px;
  font-weight: 600;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  margin-left: 5px;
  margin-bottom: 5px;
  width: 20%;
  align-items: center;
}

.icon {
  margin-right: 15px;
  display: flex;
}

.discription {
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
}

.stickyAppBar {
  margin-right: 10px;
  position: sticky;
  top: 0;
  background-color: #f1effa;
  z-index: 1000;
  box-shadow: 0 3px 3px -3px rgb(197, 195, 195);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.app {
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
