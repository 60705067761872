.tabsContainer {
  margin-top: 12px;

  .tabsBox {
    width: 100%;
    display: flex;

    label {
      height: 32px;
      padding: 8px 5px 8px 5px;

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.005em;
        color: var(--color-gray-02);
        padding-left: 0px;
        white-space: nowrap
      }
    }
  }

  .numberBox {
    width: auto !important;
    margin-top: 12px;
  }

  .rangeSeparator {
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding-left: 0px;
    margin-top: 16px;
  }
}