.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
}

.containerText {
  margin: 25px 35px;
  text-align: center;
}

.containerText p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin: 10px;
}

.containerText section {
  font-size: 14px;
}

.animation {
  padding-top: 20px;
  border-radius: 8px;
}

.animation video {
  max-height: 320px;
  border-radius: 8px;
}

.checkout {
  overflow: auto;
  width: 100%;
  height: 350px;
}

////////
///
///
/// /
///

.paymentSummaryModal {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  height: 100%;
  box-shadow: 0px 0px 5px 5px rgb(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 12px;
  margin-top: 30px;
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
}

.summaryList {
  .listItem {
    display: flex !important;
    justify-content: space-between !important;
    // margin-bottom: 16px !important;
    // padding-bottom: 16px;
    border-bottom: 1px solid #f0f0f0;

    &:last-child {
      border-bottom: none;
    }
  }

  .summaryItem,
  .summaryTotal {
    span {
      font-size: 16px;
      &:first-child {
        color: #555;
        font-weight: normal;
      }
      &:last-child {
        color: #000;
        font-weight: bold;
      }
    }
  }

  .planName {
    color: #007bff;
    font-weight: bold;
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.cardInfoSection,
.poweredByStripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
}

.cardIcon {
  font-size: 20px;
  margin-right: 8px;
}

.cardText {
  font-size: 16px;
}

.actionButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
}

.reviewButton,
.payButton {
  width: 200px;
  border-radius: 8px;
  font-weight: bold;
}

.payButton {
  background-color: #7043ff;
  border-color: #7043ff;
  &:hover {
    background-color: #7d56ff;
    border-color: #7043ff;
  }
}

.poweredByStripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  .poweredBy {
    margin-right: -3px;
    font-size: 14px;
    font-weight: 600;
    color: #666;
  }

  .stripeLogo {
    height: 24px;
  }
}

.confirmationSection {
  display: flex;
  justify-content: center;
  text-align: center;
  // margin-top: 16px;

  .confirmationIcon {
    color: #7043ff;
    font-size: 22px;
    margin-right: 10px;
  }

  .confirmationText {
    font-size: 14px;
    color: #666;
  }
}

.loading {
  background-color: white;
  // min-height: 380px;
  margin-top: 150px;
  margin-bottom: 190px;
  display: flex;
  align-items: center;
}

.completed {
  background-color: white;
  // min-height: 380px;
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  h2 {
    margin: 0;
    font-weight: 700;
  }
}

.discountCodeInput {
  display: flex;
  justify-content: center;
  // margin-top: 20px;
  // margin-bottom: 20px;
  Button {
    margin-left: 5px;
    border-radius: 8px;
  }
}

.hasDiscountCode {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 16px 0px;
  span {
    cursor: pointer;
    font-size: 14px;
    color: #666;
  }
}

.outreachSuitePopover {
  font-size: 10px;
  margin: 0px 2px;
  position: relative;
  top: 1px;
}
