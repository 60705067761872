.container {
  display: flex;
  flex-direction: column;
  span {
    color: #2aa58e;
  }
  p {
    margin: 2px 0px;
  }
}

.rightSide {
  text-align: right;
  span {
    color: #7043ff;
    cursor: pointer;
  }
}
