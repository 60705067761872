.contentStyle {
  min-height: 120;
  background-color: white;
  padding: 0px;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;

    img {
      margin-top: 30px;
      height: 45px;
    }

    p {
      margin: 10px 0px 25px 0px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
    }

    // span {
    //   font-size: 14px;
    //   margin-bottom: 20px;
    // }

    Button {
      border-radius: 8px;
      width: 200px;
      text-align: center;
      margin: 0px 5px;
    }
    .footer {
      display: flex;
      margin-bottom: 20px;
    }
  }
}

.addNewContainer {
  display: flex;
  Input {
    width: 100%;
    border-radius: 8px 0px 0px 8px;
    border-right: none;
    border-color: #7043ff;
  }
}

.rightButton {
  Button {
    border-radius: 0px 8px 8px 0px !important;
    border-left: none;
    background-color: #7043ff;
    color: white;
    font-weight: 600;
  }
  Button:hover {
    color: white !important;
    background-color: #835dff;
  }
}

.createNew {
  padding: 10px 0px 10px 0px;
  // border-bottom: 1px solid #ebecf0;
  Button {
    border-radius: 8px;
    width: 100%;
  }
}

.emails {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   width: 200px;
  margin-bottom: 10px;
}

.emailItem {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 320px;
  .removeItem {
    color: #7043ff;
    cursor: pointer;
    font-weight: 600;
    margin-left: 15px;
  }
  .copyLink {
    font-weight: 600;
    cursor: pointer;
    color: #7043ff;
    margin-right: 15px;
  }
  .assigned {
    font-weight: 600;
    color: #17b047;
    margin-right: 15px;
  }
}

.zeroSeats {
  // margin-bottom: 20px;
  p {
    margin-bottom: 30px !important;
  }
  Button {
    border-radius: 8px !important;
    background-color: #7043ff;
    color: white;
    font-weight: 600;
    height: 40px;
  }
  Button:hover {
    color: white !important;
    background-color: #835dff;
  }
}

.emailsAssigned {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 1px solid #7043ff;
  // padding: 10px;
  // border-radius: 8px;
}

.seatContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
  .seatType {
    font-size: 16px !important;
    margin-bottom: 24px !important;
    margin-top: 0px !important;
    span {
      font-size: 14px;
      margin-right: 20px;
      color: rgb(180, 180, 180);
    }
  }
}

.loading {
  background-color: white;
  min-height: 250px;
  display: flex;
  align-items: center;
}

.copyRegistrationLink {
  position: absolute;
  right: -20px;
  top: 6px;
  color: #7043ff;
  cursor: pointer;
  font-weight: 600;
}
