.loading-box {
  text-align: center;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: auto;
  }
  p {
    font-size: 12px;
  }
}

.hubspot_deal_page {
  height: 600px;
}

.hubspot_update_deal_page {
  height: 440px;
}
