.sider {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    button {
      display: flex;
      height: 40px;
      padding: 0px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: var(--primary-600-main, #7043ff);
      color: white;
      font-weight: 500;

      &:hover {
        color: white !important;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
