:root {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  --color-white: #fff;
  --color-black: #000;
  --color-white-01: #e2fff5;
  --color-white-02: #ffe4fb;
  --color-white-03: #f4f5f7;
  --color-white-04: #ebecf0;
  --color-white-05: #f9fafb;
  --color-gray-01: #97a0af;
  --color-gray-02: #5e6c84;
  --color-gray-03: #f3f4f6;
  --color-gray-04: #dfe1e6;
  --color-gray-05: #dfe1e626;
  --color-gray-06: #051123;
  --color-gray-07: #F9F8FF;
  --color-gray-08: #33032C;
  --color-blue-01: #280660; 
  --color-blue-02: #5320c0;
  --color-blue-03: #7043ff;
  --color-blue-04: #f2f0ff;
  --color-blue-05: #091e42;
  --color-blue-06: #3e1192;
  --color-blue-07: #6832EB;
  --color-blue-08: #AA93FF;
  --color-blue-09: #4EC4F6;
  --color-green-01: #50e2ba;
  --color-green-02: #3dc7a8;
  --color-green-03: #54e3bc;
  --color-green-04: #41c0b7;
  --color-green-05: #b0f8df;
  --color-green-06: #2AA58E;
  --color-green-07: #1B8374;
  --color-green-08: #10635B;
  --color-red: red;
  --color-red-01: #e62dca;
  --color-red-02: #ff4fe6;
  --color-red-03: #8D127B;
  --color-red-04: #BF1FA7;
  --color-orange: #cb7d61;
  --color-orange-1: #F6BE4E;

  --color-sidbar: 180deg, #7043FF 58.33%, #FF4FE6 100%;
  --color-transparent: transparent; 
  --color-form: rgba(255, 255, 255, 0.15);
  --color-form-login: rgba(255, 255, 255, 0.30);
  --color-signup-input-hover: rgb(112 67 255 / 23%);

  --topbar-height: 80px;
}
