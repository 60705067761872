.profileSider {
  overflow: auto;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #e9e9e9;
    img {
      margin-right: 0px !important;
    }

    .closeIcon {
      cursor: pointer;
      position: absolute;
      top: 15px;
      left: 15px;
    }
  }

  .container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 65vh;
    width: calc(100% + 10px);
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 2px !important;
    }
  }

  .activeAccounts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .profileSider ::-webkit-scrollbar {
    width: 2px !important;
  }

  .name {
    margin: 10px 0 0;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .title {
    margin: 0;
    color: gray;
  }

  .descriptions {
    background: #fff;
    margin-bottom: 2px;

    .ant-descriptions-item-label {
      font-weight: bold;
    }
  }

  .actions {
    padding: 20px;
    background: #f0f2f5;

    button {
      margin-top: 10px;
    }
  }

  .campaigns {
    border-top: 1px solid #e9e9e9;
    padding-top: 20px;
    .headerTitle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .allCampaigns {
      display: flex;
      flex-direction: column;
      // gap: 20px;
      .campaignName {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 2px;
        cursor: pointer;
        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
  }
}

.trigger {
  position: absolute;
  // top: -12px;
  right: 5px;
  // left: -40px;
  height: 110px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
}

.border {
  padding: 12px 0;
  border-top: 1px solid black;
  height: 1px;
  opacity: 0.1;
}

.chatTag {
  &__header {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 8px;
  }

  &__description {
    color: var(--Natural-700, #5e6c84);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__input {
    width: 100%;
    padding: 8px 16px;
  }
}

.hubspotSync {
  margin: 24px 0;
  padding-bottom: 60px;

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    span {
      color: var(--Natural-900, #051123);
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;
      gap: 8px;

      img {
        max-width: 24px;
        max-height: 24px;
      }

      span {
        color: var(--Natural-800, #091e42);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
        letter-spacing: 0.032px;
      }
    }
  }
}
