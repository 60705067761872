.input {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: center;
  padding-right: 10px;
  Button {
    margin-left: 20px;
  }
}

.rowStyle {
  width: calc(100% + 10px);
  padding-right: 10px;
  height: 100%;
  // overflow: hidden;
  // max-height: 613px;
  // min-height: 613px;
  align-items: flex-start;
  align-content: flex-start;
}

.colStyle {
  // flex: 1 1 auto;
}

.category {
  span {
    color: black;
  }
}

.platforms {
  display: flex;
  margin-bottom: 18px;
}

.categoryHeader {
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  .leftSide {
  }

  .rightSide {
    color: #7043ff;
    cursor: pointer;
    padding: 5px;
    border-radius: 12px;
    &:hover {
      background-color: #f3f4f6;
    }
  }
}
