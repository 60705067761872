.imageWithCircle {
  position: relative;
  overflow: hidden;

  img {
    max-width: 100%;
    // border: 2px solid;
    height: auto;
    display: block;
  }
}

.firstCircle {
  img {
    border: 2px solid #50e2ba;
  }
}

.secondCircle {
  img {
    border: 2px solid #7043ff;
  }
}

.thirdCircle {
  img {
    border: 2px solid #ff4fe6;
  }
}

.firstNumber {
  background-color: #50e2ba;
}

.secondNumber {
  background-color: #7043ff;
}

.thirdNumber {
  background-color: #ff4fe6;
}

.numberCircle {
  img {
    border: none !important;
    height: 15px !important;
    width: 15px !important;
    border-radius: 0% !important;
    margin-left: 6px !important;
  }
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 700;
  color: white;
}
