.tab {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #dfe1e6;

  &__item {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    cursor: pointer;
    background: white;

    &--active {
      border: 1px solid var(--Linear, #7144ff);
      background: white;
      overflow: hidden;
      color: var(--Natural-900, #051123);
      text-overflow: ellipsis;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.028px;

      &--first {
        border-radius: 10px 0px 0px 10px;
      }

      &--last {
        border-radius: 0px 10px 10px 0px;
      }
    }
  }
}
