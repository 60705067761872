.trialBanner {
  background-image: url('../../../common/assets/svg/outreach_banner.svg');
  background-size: cover;
  //   background-position: center;
  // background-color: #5d5fef;
  background-color: #091e42;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  justify-content: space-between;
  margin: 20px 20px 0px 20px;
  border-radius: 8px;
  position: relative;
}

.trialText {
  font-family: 'Roboto';
  font-size: 20px;
  margin: 10px;
  align-items: center;
  display: flex;
  p {
    margin-left: 45px;
  }

  span {
    margin-left: 5px;
    color: #ffc805;
    font-weight: 600;
  }
  .images {
    position: absolute;
    bottom: 0;
    height: 45px;
    img {
      height: 45px;
    }
  }
}

.trialButton {
  font-family: 'Roboto';
  font-weight: 600;
  margin-left: 20px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 20px;
}

.rightSide {
  display: flex;
  align-items: center;
  margin-right: 20px;
  img {
    height: 55px;
    // margin-right: 20px;
    position: absolute;
    top: 0;
    right: 60px;
  }
}

// .trialBanner {
//   background-image: url('../../../common/assets/svg/outreach_banner.svg');
//   background-size: cover;
//   //   background-position: center;
//   // background-color: #5d5fef;
//   background-color: #091e42;
//   color: #fff;
//   padding: 10px;
//   display: flex;
//   align-items: center;
//   font-family: 'Roboto';
//   justify-content: space-between;
//   margin: 20px 20px 0px 20px;
//   border-radius: 8px;
// }

// .trialText {
//   font-family: 'Roboto';
//   font-size: 20px;
//   margin: 10px;
//   display: flex;
//   align-items: center;

//   span {
//     margin-left: 5px;
//     color: #ffc805;
//     font-weight: 600;
//   }
//   img {
//     margin-right: 5px;
//     height: 45px;
//   }
// }

// .trialButton {
//   font-family: 'Roboto';
//   font-weight: 600;
//   margin-left: 20px;
//   color: white;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;
//   margin-right: 20px;
// }

// .rightSide {
//   display: flex;
//   align-items: center;
//   margin-right: 20px;
//   img {
//     height: 45px;
//     margin-right: 10px;
//   }
// }
