.groupsContent {
  padding: 24px;
  background-color: #f0f2f5;
}

.groupCard {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 24px;
  // padding: 0px 10px;

  .cardImage {
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    overflow: hidden;
    white-space: nowrap;
    .groupName {
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .leads {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: normal;
    margin-bottom: 12px;
  }

  .numberOfLeads {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      text-overflow: ellipsis;
    }
    img {
      width: 19px;
      margin-right: 4px;
    }
  }
  .groupStatus {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    span {
      text-overflow: ellipsis;
      padding-top: 2px;
    }
    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .link {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .platform {
    display: flex;
    align-items: center;
    img {
      height: 16px;
      margin-right: 4px;
    }
  }

  .cardBody {
    .groupDescription {
      margin-bottom: 16px;
      color: #97a0af;
      font-size: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid #ebecf0;
      min-height: 42px;
      max-height: 42px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      
    }
    .noTag {
      border: none;
      margin-bottom: 0;
    }

    .groupTags {
      .tag {
        display: inline-block;
        background-color: #f2f0ff;
        color: #7043ff;
        border-radius: 8px;
        padding: 2px 8px;
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }
}

.noTags {
  margin-bottom: 16px;
  color: #97a0af;
  font-size: 12px;
  padding-bottom: 12px;
  min-height: 50px;
  max-height: 50px;
}

.progress {
  padding: 4px 0px;
}
.disabled {
  cursor: not-allowed;
  filter: grayscale(10%);
  opacity: 0.6;
  pointer-events: none;
}
