.content{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--color-gray-06);
    span{
        color: var(--color-blue-03) !important;
        cursor: pointer;
    }
}