.container {
  display: flex;
  flex-direction: column;
  padding: 20px;

  p {
    font-weight: 700;
    color: gray;
    margin: 5px 0px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  p {
    font-weight: 600;
    color: black;
    font-size: 20px;
  }
}

.footer {
  Button {
    margin-top: 10px;
    border-radius: 8px;
    height: 45px;
    color: white;
    width: 100%;
    background-color: #7043ff;
  }
  Button:hover {
    color: white !important;
    background-color: #7f58ff;
  }
}

.customSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  Button {
    border-radius: 8px;
    background-color: #7043ff;
    color: white;
  }

  Button:hover {
    background-color: #825dff !important;
    color: white !important;
  }
}
