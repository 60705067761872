.container {
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin: 10px 0px 0px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebecf0;

  img {
    width: 20px;
    margin-right: 10px;
  }

  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #7043ff;
  }
}

.section {
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 20px 0px;

  .rightArrow {
    width: 15px;
    height: 15px;
  }

  .item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
  }

  span {
    font-size: 12px;
    color: #777;
  }
}

.section:hover {
  background-color: #f9f9f9;
}
