.companiesFooter {
  position: sticky;
  bottom: 0px;
  height: 60px;
  max-height: 60px;
  background-color: var(--color-white);
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;

  .verticalLine {
    border-left: 2px solid var(--color-gray-04);
    height: 25px;
    width: 0.5px;
    float: left;
    padding-right: 12px;
    margin-left: 12px;
  }
}
