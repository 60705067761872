.toggleContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.toggleOption {
  padding: 7px 12px;
  background-color: white;
  border: 1px solid #dfe1e6;
  color: #5e6c84;
  border-radius: 2px;
  transition: background-color 0.4s, border-color 0.4s, color 0.4s;
}

.rightToggle {
  border-radius: 0px 8px 8px 0px;
}

.rightToggle.notActive {
  border-left: none !important;
}

.leftToggle {
  border-radius: 8px 0px 0px 8px;
}

.leftToggle.notActive {
  border-right: none !important;
}

.toggleOption.active {
  background-color: #f2f0ff;
  border-color: #7043ff;
  color: #7043ff;
}
