.container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  .header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    img {
      height: 18px;
      margin-right: 10px;
      filter: brightness(0%);
    }

    span {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }
}

.enrichment {
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 15px 20px;
  font-family: Roboto;
  line-height: 16px;
  letter-spacing: 0.004em;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 10px;

    .left {
      p {
        font-size: 16px;
        font-weight: bold;
        margin: 5px 0px;
      }

      span {
        font-size: 12px;
        color: #666;
      }
    }

    .right {
      display: flex;
      align-items: center;
      width: 80px;

      flex-direction: row;
      justify-content: space-around;

      span {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .bottom {
    display: flex;
    margin-top: 5px;
    align-items: center;
    span {
      font-weight: 600;
      font-size: 14px !important;
      color: black !important;
    }
  }
}
