.modal_outreach_connection {
  display: flex;
  width: 1050px !important;
  overflow: auto !important;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
  flex-grow: 1;
}

.containerText {
  margin: 25px 35px;
  text-align: center;
}

.containerText p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin: 10px;
}

.containerText section {
  font-size: 14px;
}
.parent {
  display: flex;
}
.details {
  display: flex;
  min-width: 290px;
  height: 250px;
  padding: 30px;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: rgba(242, 240, 255, 0.5);

  .userName {
    color: var(--nutrals-100, #051123);
    /* Title/Title small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.014px;
  }
  .telegram {
    color: var(--nutrals-300, #5e6c84);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.06px;
  }
  .bio {
    color: var(--secondary-500, #2aa58e);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.06px;
  }
}

.img {
  width: 105px !important;
  height: 100%;
  cursor: pointer;
}

.parentImg {
  width: 105px !important;
  height: 105px;
  border-radius: 105px;
  margin-bottom: 10px;
}

.form {
  margin: 10px 20px;

  .input_caption {
    margin: 20px 0px 5px;
    color: var(--nutrals-100, #051123);
    /* Title/Title small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.014px;
  }

  .margin_user_name {
    margin: 8px 0px 5px;
    color: var(--nutrals-100, #051123);
    /* Title/Title small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.014px;
  }
}

.recommended {
  color: var(--nutrals-300, #5e6c84);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
}

.color_2AA58E {
  color: var(--secondary-500, #2aa58e);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.06px;
}

.saveButton {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  Button {
    width: 250px;
    height: 45px;
    border-radius: 8px;
  }
}
