.channelsContainer {
  width: 100%;
  display: flex;
  padding: 6px;
  border-radius: 40px;
  // background-color: #f2f0ff;
  // justify-content: center;

  .iconWrapper {
    width: 26px;
    height: 26px;
    padding: 12px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Add this to your CSS file */
  }

  // .linkedin {
  //   img {
  //     // filter: invert(31%) sepia(100%) saturate(11319%) hue-rotate(256deg) brightness(99%) contrast(106%);
  //   }
  // }

  img {
    // padding: 2px;
    height: 20px;
    width: 20px;
    margin: 4px;
  }
}

.disabled {
  padding: 4px;
  cursor: not-allowed;
  opacity: 0.4;

  img {
    padding: 1px;
    height: 24px;
    width: 24px;
    margin: 4px;
  }
}

.company_logo {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.table__text {
  overflow: hidden;
  color: var(--Natural-700, #5e6c84);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;

  &__name {
    overflow: hidden;
    color: var(--Link, #005af4);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.07px;
  }

  &__role {
    overflow: hidden;
    color: var(--Natural-700, #5e6c84);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  &__company {
    overflow: hidden;
    color: var(--Nutrals-300, #5e6c84);
    text-overflow: ellipsis;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.07px;
  }
}

.tag {
  background: var(--color-blue-04);
  color: #7043ff;
}
