.title {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #97a0af;
}
.footer {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  Button {
    padding: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.002em;
    text-align: left;
    gap: 8px;
  }
}
