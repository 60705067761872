.expandableChildren {
  min-height: 32px;
  border-radius: 8px;
  height: auto;
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: 1px solid #ebecf0;
}

.expanded {
  padding-top: 13px;
  padding-bottom: 16px;
}

.numberBox {
  width: auto !important;
  margin-top: 12px;
}

.soonTag {
  position: absolute;
  right: 50px;
  top: 17px;
}

.soonTagSentiments {
  position: absolute;
  right: 50px;
  top: 7px;
}

.soonTagCheckbox {
  position: absolute;
  right: 20px;
}

.checkboxFilter {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 8px;
  label {
    margin-inline-start: 0px !important;
  }
}

.itemLabel {
  padding-right: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-gray-02);
  float: left;
}

.checkboxClass {
  span {
    color: black !important;
  }
}
