.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
}

.containerText {
  margin: 25px 35px;
  text-align: center;
}

.containerText p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin: 10px;
}

.containerText section {
  font-size: 14px;
}

.animation {
  padding-top: 30px;
  border-radius: 8px;
}

.animation video {
  max-height: 250px;
  border-radius: 8px;
}
