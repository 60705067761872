.logoMenu {
  margin-bottom: 52px;
  li {
    // height: 46.45px !important;
    padding-left: 2px !important;
    background-color: transparent !important;
    img {
      width: 40px;
      height: 46.45px;
    }

    span {
      text-align: left;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.03em;
      color: var(--color-white);
    }

    span img {
      width: 100px !important;
      height: 46.45px;
    }
  }
}
