.enrichData {
  &__body {
    height: calc(100vh - 390px);
    max-height: calc(100vh - 390px);
    padding: 32px 48px;
    overflow: auto;
  }

  &__footer {
    border-top: 1px solid #ebecf0;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    &__skip {
      color: var(--primary-600-main, #7043ff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.028px;
      border-radius: 16px;
      border: 1px solid var(--Nutrals-600, #dfe1e6);
    }

    &__approve {
      border-radius: 16px;
      background: var(--primary-600-main, #7043ff);
      color: var(--Nutrals-1000, #fff);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.028px;

      &:hover {
        color: white !important;
      }
    }
  }
}
