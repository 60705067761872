.container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // width: 150px;
  span {
    width: fit-content;
  }
}

.tooltipContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
