.score-details-container {
  line-height: 10px;
  margin-top: 10px;
  transition: height 0.5s ease;
}

.score-details-header {
  display: flex;
  align-items: center;
  position: relative;

  span {
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0015em;
    font-size: 14px;
  }
}

.score-drop-down {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.score-details-content {
  padding: 10px 10px 5px 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
}

.score-drop-down.open {
  max-height: 500px;
}

.score-details-subtitle {
  font-size: 12px;
  color: #666;
}

.score-details-item {
  padding: 0 !important;
  div {
    align-items: center !important;
  }
}

.icon-check {
  color: green;
  font-size: 16px;
}

.icon-cross {
  color: red;
  font-size: 16px;
}

.score-details-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  span {
    font-weight: 400;
  }
}

.score-positive {
  color: #7043ff;
  font-weight: bold;
}

.score-negative {
  color: red;
  font-weight: bold;
}

.score {
  font-size: 18px;
  font-weight: 600;
  margin-left: 8px;
}

.score-red {
  color: #ff0000;
}

.score-yellow {
  color: #dcd800;
}

.score-green {
  color: #06b500;
}

.icon-detail {
  cursor: pointer;
  margin: 0 10px;
  font-size: 16px;
  color: #777777;

  &:hover {
    color: #222;
  }
}
