.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  img {
    margin-left: 5px;
  }
}
