.item {
  min-height: 48px;
  height: auto;
  border-bottom: 1px solid var(--color-gray-03);
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

.item:hover {
  background-color: #fafafa;
  // span {
  //   color: #7143ff;
  // }
}

.expanded {
  padding-top: 13px;
  padding-bottom: 16px;
  background: #fafafa;
  // background: var(--color-gray-03);
}

.expanded:hover {
  background-color: #fafafa;
  // background-color: var(--color-gray-03);
}

.soonTag {
  position: absolute;
  right: 55px;
  top: 17px;
}

.companyItem {
  border: 1px solid var(--color-blue-08);
  background-color: var(--color-blue-04);
  height: 32px;
  width: 100%;
  border-radius: 8px;
  margin-top: 8px;

  .label {
    color: var(--color-blue-05);
    padding: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    float: left;
  }

  .close {
    width: 18px;
    height: 18px;
    float: right;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
  }

  .unselected {
    transform: rotate(45deg);
  }
}

.unselectedCompany {
  border: 1px solid var(--color-gray-01);
  background-color: transparent;
}

.selectedCompanies {
  float: right;
  display: flex;
  position: absolute;
  margin-left: 69%;
  width: auto;
}

.selectedCompaniesExpanded {
  float: right;
  display: flex;
  position: inherit;
  margin-left: 78%;
  width: auto;
  margin-top: -25px;
}

.itemLabel {
  padding-right: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  color: var(--color-gray-02);
  float: left;
}

.purple {
  color: #7143ff;
}

.pulse {
  margin-right: 19px;
  margin-left: -3px;
}

.emptyLists {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    height: 50px;
  }

  p {
    font-weight: 600;
    margin: 8px 0px;
  }
  span {
    font-size: 11px;
  }
}

.filterListItem {
  display: flex;
  height: 40px;
  padding: 10px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background: var(--color-white);
}

.filterListItem:hover {
  outline: 1px solid var(--primary-800, var(--color-blue-08));
  background: var(--primary-960, var(--color-blue-04));
}

.filterListItem .moreOptions {
  display: none;
  float: right;
  cursor: pointer;
  line-height: 8px;
  font-weight: bold;
}

.filterListItem:hover .moreOptions {
  display: block;
}

.filterListItem img {
  float: left;
  padding-top: 3px;
  padding-right: 8px;
}

.excludeInclude {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  span {
    color: lightgray;
    font-size: 11px;
    font-weight: 600;
  }
}

.customSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  Button {
    border-radius: 8px;
    background-color: #7043ff;
    color: white;
  }

  Button:hover {
    background-color: #825dff !important;
    color: white !important;
  }
}

.exclude:hover {
  color: red;
}
.include:hover {
  color: #6884ff;
}

.checkboxes {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px 2px 10px 6px;
  border-radius: 8px;
  outline: 1px solid var(--color-white-04);
  // span {
  //   color: #5e6c84;
  // }
}

.comingSoonTag {
  display: flex;
  flex-direction: row;
  align-items: center;
}
