.container {
  text-align: center;
}
.layout {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d2d2d2;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #091e42;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollable-max-height {
    max-height: calc(100% - 80px);
  }
}
