.loading {
  background-color: white;
  min-height: 315px;
  min-width: 568px;
  display: flex;
  align-items: center;
}

.headerLoadingBoxText {
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: 300px;
}

.headerStyle {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 0px;
  border-bottom: 1px solid #ebecf0;
  img {
    width: 20px;
    margin-right: 10px;
  }

  h1 {
    font-size: 14px;
    font-weight: bold;
    color: #7043ff;
  }
}

.contentStyle {
  min-height: 120;
  background-color: white;
  padding: 30px 0px;

  .container {
    padding: 0px 20px;
  }

  .header {
    margin-bottom: 10px;
  }

  .title {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin: 0px 0px 10px 0px;
  }

  .sub_title {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin: 0px 0px 10px 0px;
  }

  .description {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }

  .switchContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .switchItem {
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }

  .switchLabel {
    margin-left: 10px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
  }

  .terms {
    margin-top: 125px;
    font-size: 12px;
    color: #999;
  }
}

.footerStyle {
  padding: 16px 16px 0px 16px;
  background-color: white;
  border-top: 1px solid #ebecf0;

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .button {
      background-color: #7043ff;
      color: white;
      border-radius: 8px;
    }

    .button:disabled {
      background-color: #adadad;
      color: white;
      border-radius: 8px;
    }

    .centeredText {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      color: #5e6c84;
      min-width: fit-content;

      img {
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .back {
      cursor: pointer;
      font-family: Roboto;
      color: black;
      font-size: 14px;
      font-weight: 800;

      img {
        margin-right: 5px;
      }
    }
  }
}
