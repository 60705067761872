.numberBox {
  width: auto !important;
  margin-top: 12px;
}

.soonTag {
  position: absolute;
  top: 20px;
  right: 11px;
}
