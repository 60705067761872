.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: 0px 20px;
  background-color: #f3f4f6;
  height: 100%;
}

.header div > div::before {
  border-bottom: unset !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  height: 50px;
}

.rightSide {
  Button {
    border-radius: 8px;
    height: 40px;
  }
}
