.articleCard {
  width: 100%;
  height: 85px;
  border: 1px solid #dfe1e6;
  display: flex;
  align-items: center;
}
.articleCard:hover {
  background: linear-gradient(white, white) padding-box, linear-gradient(180deg, #7144ff, #b649f3) border-box;
  border: 1px solid transparent;
}
.articleImage {
  width: 75px;
  height: 75px;
  gap: 0px;
  object-fit: cover;
  border-radius: 4px;
  flex: 0.1;
}
.articleContent {
  flex: 0.9;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.articleTitle {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.002em;
  text-align: left;
}
.articleDescription {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  width: 280px;
  color: #5e6c84;
}
.articleMeta {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  text-align: left;
  color: #97a0af;
}
