.import-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-bottom: 1px solid #dfe1e6;
    padding: 16px 24px;

    &__left {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;

      &__setting {
        color: #091e42;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        letter-spacing: 0.048px;
        cursor: pointer;
      }

      &__pageName {
        color: #091e42;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-transform: capitalize;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 32px;

      button {
        border: none;
        background: transparent;
        box-shadow: none;
        color: var(--primary-600-main, #7043ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.028px;
      }
    }
  }

  &__body {
    padding: 30px 24px 15px;
    background: #f9fafb;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    &__left {
      grid-column: 1/3;
    }

    &__right {
      padding: 24px 24px 40px 24px;
      border-radius: 10px;
      border: 1px solid #dfe1e6;
      background: #fff;
      overflow: auto;
      position: relative;
    }
  }
}
