.billingWrapperCard {
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  height: 100%;
  width: 100%;
  overflow: auto;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
}

.cards {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 70px;
  width: 100%;
  //   text-align: center;
  //   align-items: center;
  //   width: 900px;
  //   padding: 0px 100px;
}

.billingCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  //   height: 300px;
  //   width: 300px;
}

.upgradeButton,
.getMoreButton,
.changePaymentButton {
  //   width: 200px;
}

.yourPlan {
  display: flex;
  flex-direction: column;
}

.invoicesTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   width: 500px;
}

.linkLogo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 110px;
}
