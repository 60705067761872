.modalBody {
  padding: 0px 20px;
}

.input {
  margin-bottom: 16px !important;
  border-radius: 6px !important;
  border: 1px solid var(--color-blue-08) !important;
  background: var(--color-blue-04) !important;
}

.parentModal .ant-modal-content {
  padding: 20px 0px;
}

.save-filter-title {
  height: 38px;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.028px;
  border-bottom: 1px solid #eaebf0;
  margin-bottom: 10px;
  padding: 0px 20px;
}

.blue-text-more-filters-btn {
  color: var(--color-blue-03);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
  border-color: transparent;
  background: transparent;
  box-shadow: 0 0;
}

.body_modal {
  z-index: 999999;
}
