.ant-btn {
  font-weight: 500;
  font-size: 14px;

  border-radius: 100px;
}

.purple-text-btn {
  color: #7043ff;
}

.gray-text-btn {
  color: #97a0af;
}

.blue-text-btn,
.blue-text-clear-filters-btn {
  color: var(--color-blue-03);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.002em;
}

.blue-text-more-filters-btn:hover {
  color: var(--color-blue-03) !important;
  border-color: transparent !important;
  background: transparent;
  box-shadow: 0 0;
}

.blue-text-clear-filters-btn:hover {
  color: var(--color-blue-03) !important;
  background-color: transparent !important;
}

.text-save-filters-btn {
  border: 1px solid var(--color-blue-03);
  border-radius: 4px;
  background: var(--color-white);
  color: var(--primary-600-main, #7043ff);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.06px;
}

.text-save-filters-btn:hover {
  color: var(--primary-600-main, #7043ff) !important;
  background: var(--color-white) !important;
}

:where(.css-dev-only-do-not-override-18bgij0).ant-tree .ant-tree-checkbox-disabled,
:where(.css-dev-only-do-not-override-18bgij0).ant-tree .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  display: none !important;
}

.ant-tree-checkbox.ant-tree-checkbox-disabled {
  width: 0px !important;
  opacity: 0 !important;
  margin-left: -10px;
}

:where(.css-dev-only-do-not-override-18bgij0).ant-tree .ant-tree-switcher {
  width: 20px !important;
}

.ant-input-number-group-addon {
  padding: 0 3px !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  bottom: 10px;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  flex-wrap: nowrap;
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
  margin-left: 5px;
  margin-right: 5px;
}

.ant-spin-nested-loading .ant-spin-container {
  position: initial;
}

.ant-spin-nested-loading {
  position: initial;
}

.tagsList {
  margin-top: 10px;
}

.tagsList .anticon.anticon-close {
  margin-top: 7px;
  font-size: 100%;
}

.ant-table-selection-column {
  min-width: 50px;
  margin-right: -10px !important;
  padding-inline-end: 0px;
  padding-inline-start: 0px;
}

.tabSection .ant-progress-outer {
  float: left;
}
