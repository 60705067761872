.bottomMenu {
  text-align: left;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.03em;
  color: var(--color-white);
  // position: fixed;
  // padding: 0px 0px 0px 6px !important;
  img {
    width: 32px;
    height: 32px;
  }
}
