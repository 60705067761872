.container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  .header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    img {
      height: 18px;
      margin-right: 10px;
      filter: brightness(0%);
    }

    span {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .senderTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .inputText {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    span {
      font-family: Roboto;
      font-weight: 600;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .variables {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-bottom: 25px;

    span {
      font-family: Roboto;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }

  .messageSuggestions {
    display: flex;
    flex-direction: column;
    align-items: left;

    span {
      font-family: Roboto;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.0015em;
      text-align: left;
    }
  }
}

.generateWithAI {
  cursor: pointer;
  position: absolute;
  top: 209px;
  left: 12px;
  display: flex;
  align-items: center;
  z-index: 1000;
  img {
    height: 16px;
    margin-right: 5px;
    filter: brightness(0%);
  }
}

.divider {
  cursor: pointer;
  position: absolute;
  top: 176px;
  display: flex;
  z-index: 1000;
  width: 100%;
}

.compare_A_B_testing {
  color: #da1818 !important;
  line-height: 20px;
  margin-top: 8px;
}

.saveBtn{
  color: #97A0AF;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border: none;
  padding: 4px 12px;
  
}
.saveBtn:hover{
  border-radius: 16px;
  color: #7043FF;
  background: #F3F4F6;
  padding: 4px 12px;
  cursor: pointer;
}