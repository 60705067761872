.optionLabel {
  display: flex;
  align-items: center;
}

.flag {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-right: 6px;
}
