.container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: white;
  // height: var(--start-screen-height);
  height: 75%;
  margin-top: 20px;
  // margin-bottom: 20px;
  max-height: 600px !important;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.topHeader {
  font-weight: 600;
  text-align: center;
  p {
    margin-top: 0px;
  }
}

.filters {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.main {
  text-align: center;
  p {
    font-weight: 600;
    font-size: 16px;
  }
  img {
    height: 220px;
  }
}

.arrow {
  position: absolute;
  top: 70px;
  left: 10px;
  align-items: center;
  text-align: center;
  p {
    margin-bottom: 0px;
  }
  img {
    height: 15px;
    margin-right: 60px;
  }
}

// Old start screen
// .container {
//   width: 95%;
//   height: 90%;
//   display: flex;
//   flex-direction: column;
//   margin-left: 40px;
// }

// .title {
//   font-size: 20px;
//   font-weight: bold;
//   font-family: 'Roboto';
// }

// .discoverFilter {
//   width: 32%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 24px 4px 24px 6px;
//   border-radius: 8px;
//   cursor: pointer;
//   color: white;
//   background-color: #7043ff;
// }

// .discoverContainer {
//   height: 15%;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   justify-content: space-between;
// }

// .recentlyContainer {
//   display: flex;
//   flex-direction: column;
//   max-height: 50%;
//   border-radius: 8px;
//   justify-content: space-around;
//   align-items: center;
//   background-color: white;
// }

// .recently {
//   height: 20%;
//   width: 100%;
//   cursor: pointer;

//   h5 {
//     margin-left: 12px;
//     margin-right: 12px;
//     color: #5e6c84;
//     font-family: 'Roboto';
//     font-size: 14px;
//     font-weight: 500;
//     line-height: 20px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }

// .subtitle {
//   font-family: 'Roboto';
// }

// .filterLabel {
//   max-width: 55%;
// }
