.wizardBlock {
  position: relative !important;
  display: flex;
  min-height: 100px;
  margin: 10px 0px;
}

.wizardBlock:hover {
  opacity: 0.85;
}

.circleNumber {
  width: 30px;
  height: 30px;
  background-color: white;
  color: #7043ff;
  border: 2px solid #7043ff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
}

.customNodeWrapper {
  margin-bottom: 10px;
}

.numberOfFollowups {
  display: flex;
  justify-content: space-between;
}

.slider {
  width: 100%;
  padding-top: 10px;

  span {
    font-weight: 600;
  }
}

.container {
  position: relative !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  // outline: 1px solid #7043ff;
  box-shadow: 0 0 2px 1px rgb(0, 0, 0, 0.2);
  border-radius: 12px;
  //   width: fit-content;
  margin-left: 30px;
  //   padding: 0px 70px 0px 0px;
  img {
    width: fit-content;
    height: fit-content;
  }
}

.gray {
  // filter: grayscale(1) opacity(30%);
}

.node {
  width: 300px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  color: black;
  font-weight: 600;
  border-radius: 8px;
  //   margin: 0px 45px;
  img {
    margin-left: 5px;
    margin-right: 10px;
  }
}

.node.multi {
  background-color: #ff6347 !important;
}

.node:focus-visible {
  background-color: #ff6347;
}

.body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  .createCheckbox {
    display: flex;
    align-items: center;
  }
  .updateCheckbox {
    display: flex;
    align-items: center;
  }
}

.comingSoon {
  position: absolute;
  right: 0;
  top: 0;
}

.sliderMax {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  span {
    line-height: 1;
  }
}
