.importMapping {
  height: calc(100vh - 320px);
  padding: 16px;
  position: relative;

  &__isCompanyImport {
    height: calc(100vh - 220px);
  }

  &__title {
    color: var(--Link, #005af4);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 228.571% */
    text-decoration-line: underline;
  }

  &__description {
    color: #97a0af;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.07px;
  }

  &__loading {
    &__title {
      color: #000;
      text-align: center;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 24px 0 8px;
    }

    &__description {
      color: var(--Nutrals-300, #5e6c84);
      text-align: center;

      /* Body/Body large */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: 0.08px;
    }
  }

  &__result {
    padding: 16px 48px;
    &__upload {
      &__title {
        overflow: hidden;
        color: #091e42;
        text-align: justify;
        font-feature-settings: 'salt' on;
        text-overflow: ellipsis;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 8px;
      }

      &__description {
        color: rgba(9, 30, 66, 0.6);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        margin-bottom: 24px;
      }

      &__bar {
        display: flex;
        justify-content: space-between;
        margin: 24px 0;
        border-radius: 10px;
        border: 1px solid #dfe1e6;
        padding: 16px;

        &__left {
          display: flex;
          align-items: center;
          gap: 16px;

          article {
            &:first-child {
              overflow: hidden;
              color: #091e42;
              text-align: justify;
              font-feature-settings: 'salt' on;
              text-overflow: ellipsis;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 8px;
            }

            &:last-child {
              overflow: hidden;
              color: var(--Natural-700, #5e6c84);
              text-align: justify;
              font-feature-settings: 'salt' on;
              text-overflow: ellipsis;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        &__right {
          display: flex;
          align-items: center;
          gap: 32px;

          &__button {
            color: #7043ff;
            border: none;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }

    &__tableMapping {
      max-height: calc(100vh - 560px);
      height: calc(100vh - 560px);
      overflow: auto;

      &__isCompanyImport {
        max-height: calc(100vh - 460px);
        height: calc(100vh - 460px);
      }
    }
  }

  &__body {
    display: flex;
  }

  &__footer {
    position: absolute;
    bottom: 15px;
    right: 15px;

    &__button {
      border-radius: 16px;
      background: var(--primary-600-main, #7043ff);
      color: white;

      &--disabled {
        border-radius: 16px;
        color: #ffffff !important;
        background: var(--Nutrals-500, #97a0af) !important;
      }
    }
  }
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
}
