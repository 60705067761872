.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
  flex-grow: 1;
}

.containerText {
  margin: 25px 35px;
  text-align: center;
}

.containerText p {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin: 10px;
}

.containerText section {
  font-size: 14px;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: rgba(242, 240, 255, 0.5);
  padding: 30px;
  width: 760px;
  height: 250px;

  .userName {
    margin: 10px;
    color: var(--nutrals-100, #051123);
    text-align: center;
    /* Title/Title large */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
  }
  .telegram {
    margin: 5px;
    color: var(--nutrals-100, #051123);
    text-align: center;
    /* Title/Title small */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.014px;
  }
  .bio {
    margin: 5px;
    color: var(--nutrals-100, #051123);
    text-align: center;
    /* Body/Body medium */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.035px;
  }
}

.img {
  width: 105px;
  height: 105px;
  border-radius: 105px;
  border: 1px solid var(--nutrals-600, #dfe1e6);
}
